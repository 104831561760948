import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
//Icons
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import LabelIcon from '@material-ui/icons/Label';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//Material ui.
import {
  Box, Chip, Dialog, DialogTitle, FormControl, FormGroup, IconButton,
  InputLabel, List, ListItem, ListItemText, MenuItem, Select, Snackbar,
  Step, StepLabel, Stepper,
} from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { getControlDay, getNextWeekMaxToday } from '../../helpers/common';
import MultiNode from './MultiNode';
//Componentes
import { Alert } from '@mui/material';
import NodesContext from '../../context/NodesContext';
import TagsContext from '../../context/TagsContext';
import DatePickerInstant from '../DatePicker/DatePickerInstant';
import DynamicSelect from '../common/DynamicSelect';
//Servicios
import { getStatusNodes } from '../../services/meters';
//Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';
//helpers.
import { blueBar } from '../../helpers/common'

let scrollsSettings = {
  overflow: 'scroll',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    ...scrollsSettings,
    height: '60%',
    '@media (max-width: 920px)': {
      height: '70%',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiGrid-root': {
      padding: theme.spacing(1),
    },
    '& .MuiStepper-root': {
      padding: '1px',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: theme.spacing(0.5),
    },
  },
  steppersFilter: {
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      martinRight: '20px',
    },
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  devicesBtn: {
    margin: theme.spacing(1, 1),
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  // Usar clsx con addBtn
  addBtnSec: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
    '@media (max-width: 700px)': {
      fontSize: '13px',
      padding: '1%',
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  scrollableContainer: {
    height: 'max-content',
  },
  filterLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  containerTags: {
    ...scrollsSettings,
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '15vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiList-root.MuiList-dense': {
      // marginRight: "10px",
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  containerNodes: {
    paddingRight: '5px',
    ...scrollsSettings,
    height: '50vh',
    '@media (max-width: 960px)': {
      height: '32vh',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '& .MuiList-root.MuiList-dense': {
      // marginRight: "10px",
    },
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },
  wordCut: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  wrapperDatepicker: {
    position: 'relative',
    width: '100%',
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
  },
  secondaryContainer: {
    border: `1px dashed ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    // padding: theme.spacing(1),
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5)
  },
  fullWidth: {
    width: '80%',
  },
  granularity2Style: {
    width: '90%',
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  }
}));

export default function Filter({
  filter, setFilterOptions, limitDate, onSubmit,
  setFilterPanel, handleDateChange, varsCatalogo, isBasic
}) {
  const classes = useStyles();
  const blueLine = blueBar()
  const { isMobile } = useDeviceDetect(990);
  const { nodes } = useContext(NodesContext);
  const { tags } = useContext(TagsContext);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [showDevices, setShowDevices] = useState(false);
  const [primaryList, setPrimaryList] = useState([]);
  const [secondaryList, setSecondaryList] = useState([]);
  const [listType, setListType] = useState('');
  const [editingId, setEditingId] = useState(0);
  const basicGranularities = ['5 Minutos', '15 Minutos']
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'warning',
  });
  const [filterNotification, setFilterNotification] = useState({
    open: false,
    message: '',
    severity: 'warning',
  });
  const [activeStep, setActiveStep] = useState(0);
  const [statusNodes, setStatusNodes] = useState([]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])

  // Quiero que cada vez que cambie la fecha desde el filtro se actualice el DatePicker de acuerdo a filter
  useEffect(() => {
    setFilterOptions(filter);
  }, [filter, setFilterOptions]);

  //Para recordar el valor seleccionado en caso de haber cerrado el panel de filtros
  useEffect(() => {
    if (filter.dispositivos.length > 0) {
      setPrimaryList(filter.dispositivos);
    }
    if (filter.dispositivos2.length > 0) {
      setSecondaryList(filter.dispositivos2);
    }
  }, [filter.dispositivos, filter.dispositivos2]);

  const handleFilterChange = e => {
    const { name, value } = e.target;
    // Si modifiqué la variable y todavía no elegí granularidad estoy en el paso 3
    if (name === 'variable') {
      //Busco por defecto la primer granularidad de la opcion.
      let granularity = varsCatalogo?.find(item => item.value === value)
      let period = granularity.periods[0]?.key
      if (filter.granularity === '') {
        setActiveStep(2)
      }
      //Si elije la otra variable y la granularidad q tenia coincide con una opcion valida la sigo mostrando.
      let findSameGranularity = granularity?.periods.find(item => item.key === filter?.granularity)
      return setFilterOptions({
        ...filter,
        variable: value,
        granularity: findSameGranularity ? findSameGranularity?.key : period
      });
    }
    if (name === 'variable2') {
      //Busco por defecto la primer granularidad de la opcion.
      let granularity = varsCatalogo?.find(item => item.value === value)
      let period = granularity.periods[0]?.key
      //Si elije la otra variable y la granularidad q tenia coincide con una opcion valida la sigo mostrando.
      let findSameGranularity = granularity?.periods.find(item => item.key === filter?.granularity2)
      return setFilterOptions({
        ...filter,
        variable2: value,
        granularity2: findSameGranularity ? findSameGranularity?.key : period
      });
    }
    if (name === 'granularity') {
      // Si configuré la granularidad y tengo el gráfico secundario apagado
      setActiveStep(6)
    }
    setFilterOptions({
      ...filter,
      [name]: value,
    });
  };

  const handleSecondarySwitch = e => {
    const { checked } = e.target;
    // Si apagué el gráfico secundario limpio el estado
    if (!checked) {
      setActiveStep(6);
      // Limpio el listado de dispositivos.
      setSecondaryList([]);
      setFilterOptions({
        ...filter,
        dispositivos2: [],
        showSecondary: false,
      });
      return;
    }
    // Si prendí el gráfico secundario
    setActiveStep(3);
    setFilterOptions({
      ...filter,
      showSecondary: e.target.checked,
    });
  };

  const handleDuplication = () => {
    const aux = [...filter.dispositivos];
    setSecondaryList(aux);
    setFilterOptions({
      ...filter,
      dispositivos2: aux,
    });
  };

  const handleCloseDevicesDialog = () => {
    setSelectedNodes([]);
    setSelectedLabel({});
    setShowDevices(false);
  };

  const handleDeleteNodeSelection = id => {
    setSelectedNodes(prevState => {
      const newNodes = prevState.filter(node => node.id !== id);
      return newNodes;
    });
  };

  const handleDeleteLabelSelection = () => {
    setSelectedLabel({});
  };

  // Submit en Dialog de dispositivos
  const handleSubmitSelectionItem = () => {
    setShowDevices(false);
    const list = listType === 'primary' ? primaryList : secondaryList;
    const dispo = listType === 'primary' ? 'dispositivos' : 'dispositivos2';
    const variable = listType === 'primary' ? 'variable' : 'variable2';
    let newSelection = [];
    // Voy a recibir dos tipos de situaciones.
    // a) nodo - nodo + etiqueta
    // Este es el caso que se da cuando estoy editando (editingId!==0).
    if (selectedNodes.length === 1) {
      newSelection = editingId
        ? list.map(item => {
          if (item.id === editingId) {
            return { ...item, node: selectedNodes[0], label: selectedLabel };
          }
          return item;
        })
        : [
          ...list,
          { id: uuidv4(), node: selectedNodes[0], label: selectedLabel },
        ];
    }

    if (selectedNodes.length > 1) {
      // b) array de nodos
      // Solo tengo que agregar los elementos si no forman parte de una selección anterior.
      // Puedo usar como id el id del nodo.
      newSelection = selectedNodes.map(node => ({
        id: uuidv4(),
        node,
        label: {},
      }));
    }

    // Quiero cubrir estos casos:
    // Si estoy editando un nodo (sin tag) y elijo nodo+tag o viceversa.
    // Si estoy editando un nodo y elijo algo existente.
    // Si estoy editando quiero eliminar el nodo viejo porque se supone que en newSelection ya lo tengo (sino tendria dos con el mismo id)
    let savedValues = filter[dispo];
    if (editingId) {
      // Quito el valor original, porque tengo uno nuevo
      savedValues = savedValues.filter(item => item.id !== editingId);
    }

    // Elimino los repetidos
    const filteredSelection = newSelection.filter(newItem => {
      const isDuplicate = savedValues.some(oldItem => {
        return (
          oldItem.node.nombre === newItem.node.nombre &&
          oldItem.label.nombre === newItem.label.nombre
        );
      });
      return !isDuplicate;
    });

    const mergedArray = [...savedValues, ...filteredSelection];

    if (listType === 'primary') {
      setPrimaryList(mergedArray);
    }
    if (listType === 'secondary') {
      setSecondaryList(mergedArray);
      if (mergedArray.length > 0) {
        setActiveStep(5);
      }
    }

    const hasSomeVirtualNodes = mergedArray.some(item => item.node.virtual);

    // Si tengo seleccionado algo por fase y elijo al menos un nodo virtual conmuto a un total
    if (hasSomeVirtualNodes) {
      setFilterOptions({
        ...filter,
        [variable]: '',
        [dispo]: mergedArray,
      });
    } else {
      setFilterOptions({ ...filter, [dispo]: mergedArray });
    }
    setSelectedNodes([]);
    setSelectedLabel({});
  };

  const handleDeleteSelectionItem = (id, type) => {
    const list = type === 'primary' ? primaryList : secondaryList;
    const filteredList = list.filter(item => {
      return item.id !== id;
    });

    if (type === 'primary') {
      setPrimaryList(filteredList);
      if (filteredList.length === 0) {
        // Limpio también la lista del segundo gráfico pues no es viable sin gráfico principal
        // Al limpiar paso a activaTotal por si el usuario tiene elegido algo por fase.
        setFilterOptions({
          ...filter,
          showSecondary: false,
          dispositivos: filteredList,
          variable: '',
          granularity: '',
          dispositivos2: [],
        });
        if (filter.dispositivos2.length > 0 || filter.showSecondary === true) {
          setFilterNotification({
            isOpen: true,
            msg: 'Sólo es posible visualizar un gráfico secundario con uno principal válido.',
            severity: 'warning',
          });
        }
        setSecondaryList([]);
        setActiveStep(0);
        return;
      }
      //Actualizo filter para que el submit en Historical tenga el valor actualizado si borré algo de la lista
      setFilterOptions({ ...filter, dispositivos: filteredList });
    }

    if (type === 'secondary') {
      setSecondaryList(filteredList);
      if (filteredList.length === 0) {
        setFilterOptions({
          ...filter,
          showSecondary: false,
          variable2: '',
          granularity2: '',
          dispositivos2: [],
        });
      }
      else {
        //Actualizo filter para que el submit en Historical tenga el valor actualizado si borré algo de la lista
        setFilterOptions({ ...filter, dispositivos2: filteredList });
      }
    }
  };

  const nodeList = () => {
    return nodes?.map(node => (
      <MultiNode
        node={node}
        handleSelectedNode={handleSelectedNode}
        selectedNodes={selectedNodes}
        key={node.id}
        statusNodes={statusNodes}
      />
    ));
  };

  const labelsList = () => {
    return tags?.map(tag => (
      <ListItem
        key={tag.id}
        button
        onClick={() => handleSelectedLabel(tag.nombre, tag.id)}
        selected={selectedLabel ? selectedLabel.id === tag.id : false}
        className={classes.selected}
      >
        <ListItemText primary={tag.nombre} />
      </ListItem>
    ));
  };

  const handleSelectedNode = node => {
    setSelectedNodes(prevSelectedNodes => {
      const nodeIndex = prevSelectedNodes.findIndex(
        selectedNode => selectedNode.id === node.id
      );
      // Si el nodo está en la lista lo elimino
      if (nodeIndex >= 0) {
        return prevSelectedNodes.filter(
          selectedNode => selectedNode.id !== node.id
        );
      }
      else {
        // Si el nodo no está en la lista.
        // Si estoy en modo edición y ya tenia un nodo seleccionado, como quiso elegir otro se lo impido.
        if (editingId && prevSelectedNodes.length > 0) {
          setNotification({
            isOpen: true,
            msg: 'En modo edición no es posible la selección múltiple.',
            severity: 'warning',
          });
          return prevSelectedNodes;
        }
        // Si tengo una etiqueta seleccionada
        if (selectedNodes.length > 0 && selectedLabel.id) {
          setNotification({
            isOpen: true,
            msg: 'Sólo es posible una selección múltiple si no tiene etiquetas seleccionadas.',
            severity: 'warning',
          });
          return prevSelectedNodes;
        }
        let newSelectedNodes = [];
        if (isBasic) {
          newSelectedNodes = [node]
        } else {
          newSelectedNodes = [...prevSelectedNodes, node];
        }
        isBasic ? setActiveStep(2) : setActiveStep(1)
        return newSelectedNodes;
      }
    });
  };

  const handleSelectedLabel = (label, id) => {
    if (selectedNodes.length > 1) {
      return setNotification({
        isOpen: true,
        msg: 'Sólo es posible elegir etiquetas con un único dispositivo seleccionado.',
        severity: 'warning',
      });
    }
    // Si la etiqueta elegida es la misma que la actualmente seleccionado la deselecciono
    if (label === selectedLabel.nombre && id === selectedLabel.id)
      setSelectedLabel({});
    else setSelectedLabel({ nombre: label, id: id });
  };

  const handleCancelFilterDialog = () => {
    setFilterPanel(false);
  };

  // Click en óvalo azul dentro del panel de filtrar
  const handleClickedSelectionItem = (item, type) => {
    // Como estoy editando cargo editingId con el id del item clickeado.
    setEditingId(item.id);
    // Mostrar lista de dispositivos
    // showDevices se activa siempre junto a listType
    setShowDevices(true);
    setListType(type);
    // Establezco el nodo seleccionado
    setSelectedNodes([item.node]);
    // Establezco la etiqueta seleccionada
    setSelectedLabel(item.label);
  };

  const steps = [
    'Elija el/los dispositivos gráfico principal',
    'Elija la variable principal a graficar',
    'Elija la granularidad',
    'Elija si desea mostrar un gráfico secundario',
    'Elija los días que desea graficar',
    'Haga click en CONSULTAR',
  ];

  const basicSteps = [
    'Elija el/los dispositivos gráfico principal',
    'Elija la granularidad',
    'Elija los días que desea graficar',
    'Haga click en CONSULTAR',
  ]

  //Deshabilita el selector de la variable del primer grafico.
  const disabledVariableSelectOne = (primaryList) => {
    return primaryList.length === 0 ? true : false
  }
  //Deshabilita el selector de la variable del segundo grafico.
  const disabledVariableSelectTwo = (primaryList) => {
    return !filter.showSecondary || secondaryList.length === 0
  }


  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {isBasic ? basicSteps.map(label => (
          <Step className={classes.steppersFilter} key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))
          :
          steps.map(label => (
            <Step className={classes.steppersFilter} key={label}>
              <StepLabel>{isMobile ? '' : label}</StepLabel>
            </Step>
          ))
        }

      </Stepper>

      {blueLine}

      <Box className={classes.root}>

        <Snackbar
          open={filterNotification.isOpen}
          autoHideDuration={2000}
          onClose={() =>
            setFilterNotification({ ...filterNotification, isOpen: false })
          }
        >
          <Alert
            severity={filterNotification.severity}
            onClose={() => setFilterNotification(false)}
          >
            {filterNotification.msg}
          </Alert>
        </Snackbar>

        {/* Dispositivos principales */}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            minHeight={40}
          >
            <Tooltip
              title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
              arrow
              placement="right"
            >
              <Box
                className={clsx(
                  classes.hint,
                  classes.leftSpacing,
                  {
                    [classes.active]: activeStep === 0,
                  },
                  {
                    [classes.tick]: activeStep > 0 || filter.dispositivos.length > 0,
                  }
                )}
              >
                1
              </Box>
            </Tooltip>
            <Box className={classes.devicesLabel}>
              Gráfico principal. Agregar dispositivo
            </Box>
            <IconButton
              aria-label="agregar dispositivos"
              className={classes.addBtn}
              onClick={() => {
                // showDevices se activa siempre junto a listType
                setShowDevices(true);
                setListType('primary');
                // Como no estoy editando pongo editingId en 0
                setEditingId(0);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
            {primaryList.length > 0 &&
              primaryList.map(item => (
                <Chip
                  key={item.id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={`${item.node.nombre}  ${item.label.nombre ? `+ ${item.label.nombre}` : ''
                    }`}
                  clickable
                  color="primary"
                  onDelete={() => {
                    handleDeleteSelectionItem(item.id, 'primary');
                  }}
                  className={classes.filterChip}
                  onClick={() => handleClickedSelectionItem(item, 'primary')}
                />
              ))}
          </Box>
        </Grid>

        <Grid container style={{ padding: 0 }}>
          {!isBasic &&
            <>

              {/* Variable principal */}
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  minHeight={40}
                  alignItems='flex-end'
                >

                  <Tooltip
                    title="Elija la variable principal a graficar"
                    mt={3}
                    arrow
                    placement="right"
                  >
                    <Box
                      className={clsx(classes.hint, classes.leftSpacing, {
                        [classes.active]: primaryList.length > 0 && filter.variable === '',
                        [classes.tick]: filter.variable !== '',
                      })}
                    >
                      2
                    </Box>
                  </Tooltip>

                  {/* Selector q agrupa las variables dinamico */}
                  {/* disabled={item.node === "FISICO" && primaryList.some(item => item.node.virtual)}> */}
                  <DynamicSelect
                    disabledSelect={disabledVariableSelectOne(primaryList)}
                    disabledVarianteInstant={primaryList}
                    handleChangeProp={handleFilterChange}
                    valueSelect={filter.variable}
                    keyName={'variable'}
                    InputLabelProp={'Variable principal a graficar'}
                    section={'INSTANTANEOS'}
                  />

                </Box>
                {
                  filter.dispositivos.length === 0 &&
                  <Box className={classes.help}>Debe elegir al menos un dispositivo antes de elegir la variable.</Box>
                }
              </Grid>
            </>
          }
          {/* Granularidad Principal */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              alignItems="center"
              minHeight={40}
            >
              <Tooltip
                title="Elija la granularidad"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: ((!isBasic && filter.variable !== '') && filter.granularity === '') || (isBasic && filter.granularity === '' && activeStep === 2),
                    },
                    {
                      [classes.tick]: filter.granularity !== '',
                    }
                  )}
                >
                  {isBasic ? 2 : 3}
                </Box>
              </Tooltip>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="granularityLabel" shrink>Granularidad</InputLabel>
                <Select
                  labelId="granularityLabel"
                  name="granularity"
                  value={filter.granularity}
                  onChange={handleFilterChange}
                  disabled={(!isBasic && filter.variable === '')}
                >
                  {!isBasic ? varsCatalogo?.find(item => item.value === filter.variable)?.periods.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  )) :
                    basicGranularities.map(granularity => (
                      <MenuItem key={granularity} value={granularity}>
                        {granularity}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            {
              (filter.variable === '' && !isBasic) &&
              <Box className={classes.help}>Debe elegir una variable antes de elegir la granularidad.</Box>
            }

          </Grid>
        </Grid>

        {!isBasic &&
          <>

            <Box
              className={classes.secondaryContainer}
              display="flex"
              flexDirection="column"
            >
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset">
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      title="Elija si desea mostrar un gráfico secundario"
                      arrow
                      placement="right"
                    >
                      <Box
                        className={clsx(
                          classes.hint,
                          classes.leftSpacing,
                          {
                            [classes.tick]: activeStep > 3,
                          },
                          {
                            [classes.active]: activeStep === 3,
                          }
                        )}
                      >
                        4
                      </Box>
                    </Tooltip>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="showSecondary"
                            checked={filter.showSecondary}
                            onChange={handleSecondarySwitch}
                            color="secondary"
                            disabled={primaryList.length === 0}
                          />
                        }
                      />
                    </FormGroup>
                    <Box className={classes.filterLabel}>
                      Habilitar gráfico secundario
                    </Box>
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  minHeight={40}
                >
                  <Box className={classes.devicesLabel}>
                    Gráfico secundario. Agregar dispositivo.
                  </Box>

                  <IconButton
                    aria-label="agregar dispositivos"
                    className={classes.addBtnSec}
                    onClick={() => {
                      // showDevices se activa siempre junto a listType
                      setShowDevices(true);
                      setListType('secondary');

                      // Como no estoy editando pongo editingId en 0
                      setEditingId(0);
                    }}
                    disabled={!filter.showSecondary}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <Tooltip
                    title="Copiar dispositivos del gráfico principal"
                    arrow
                    placement="right"
                  >
                    <span>
                      <IconButton
                        aria-label="Copiar dispositivos del gráfico principal"
                        className={classes.addBtnSec}
                        onClick={handleDuplication}
                        disabled={!filter.showSecondary}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>

                  {secondaryList.length > 0 &&
                    secondaryList.map(item => (
                      <Chip
                        key={item.id}
                        icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                        label={`${item.node.nombre}  ${item.label.nombre ? `+ ${item.label.nombre}` : ''
                          }`}
                        clickable
                        color="secondary"
                        onDelete={() => {
                          handleDeleteSelectionItem(item.id, 'secondary');
                        }}
                        className={classes.filterChip}
                        onClick={() =>
                          handleClickedSelectionItem(item, 'secondary')
                        }
                      />
                    ))}
                </Box>
              </Grid>
              <Grid container style={{ padding: 0 }}>
                <Grid item xs={12} md={6}  >
                  <Box mt={1}>
                    {/* Selector q agrupa las variables dinamico */}
                    {/* disabled={item.node === "FISICO" && secondaryList.some(item => item.node.virtual)}> */}
                    <DynamicSelect
                      disabledSelect={disabledVariableSelectTwo()}
                      disabledVarianteInstant={secondaryList}
                      handleChangeProp={handleFilterChange}
                      valueSelect={filter.variable2}
                      keyName={'variable2'}
                      InputLabelProp={'Variable secundaria a graficar'}
                      section={'INSTANTANEOS'}
                    />
                  </Box>


                  {
                    filter.dispositivos2.length === 0 &&
                    <Box className={classes.help}>Debe elegir al menos un dispositivo secundario antes de elegir la variable.</Box>
                  }
                </Grid>
                {/* Granularidad Secundaria */}
                <Grid item xs={12} md={6}>
                  <FormControl
                    className={classes.granularity2Style}>
                    <InputLabel id="granularity2Label" shrink>Granularidad secundaria</InputLabel>
                    <Select
                      labelId="granularity2Label"
                      name="granularity2"
                      value={filter.granularity2}
                      onChange={handleFilterChange}
                      disabled={filter.variable2 === ''}
                    >
                      {varsCatalogo?.find(item => item.value === filter.variable2)?.periods.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {option.label}
                        </MenuItem>
                      ))
                      }
                    </Select>
                  </FormControl>
                  {
                    filter.variable2 === '' &&
                    <Box className={classes.help}>Debe elegir una variable antes de elegir la granularidad.</Box>
                  }
                </Grid>
              </Grid>
            </Box>
          </>
        }



        {/* STEP 5 */}
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Tooltip
              title="Seleccione las fechas a consultar"
              arrow
              placement="right"
            >
              <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep > 4 || (filter.selectedInitialDate && filter.selectedEndDate), })}
              >
                {isBasic ? 3 : 5}
              </Box>
            </Tooltip>
            <label className={classes.filterLabel}>Seleccione las fechas a consultar</label>
          </Box>
        </Grid>


        <Box className={classes.wrapperDatepicker}>

          <label className={classes.labelDate} >
            Desde / Hasta
          </label>
          <DatePickerInstant
            getNextWeekMaxToday={getNextWeekMaxToday}
            limitDate={limitDate}
            minDay={
              new Date(
                getControlDay(new Date(), limitDate?.menu?.inst_days_historic)
              )
            }
            handleDateChange={handleDateChange}
            filter={filter}
            setFilterOptions={setFilterOptions}
            startDate={filter.selectedInitialDate}
            endDate={filter.selectedEndDate}
            displayDisabled={!primaryList.length > 0}
          />

        </Box>

        <p className={classes.help}>
          La politica de retencion de informacion de su cuenta es de{' '}
          {limitDate?.menu?.inst_days_historic} dias historicos.
        </p>

        {filter.dispositivos.length === 0 && (
          <Box className={classes.help}>
            Debe seleccionar al menos un dispositivo.
          </Box>
        )}
        {(filter.variable === "" && !isBasic) && (
          <Box className={classes.help}>
            Debe seleccionar una variable.
          </Box>
        )}
        {(filter.granularity === "" && !isBasic) && (
          <Box className={classes.help}>
            Debe seleccionar la granularidad.
          </Box>
        )}
        {filter.showSecondary && filter.dispositivos2.length === 0 && (
          <Box className={classes.help}>
            Debe seleccionar al menos un dispositivo secundario.
          </Box>
        )}
        {filter.showSecondary && filter.variable2 === "" && (
          <Box className={classes.help}>
            Debe seleccionar una variable secundaria.
          </Box>
        )}
        {filter.showSecondary && filter.granularity2 === "" && (
          <Box className={classes.help}>
            Debe seleccionar la granularidad secundaria.
          </Box>
        )}

      </Box>

      {blueLine}

      {/* Botones */}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.btn}
          disabled={!isBasic ?
            (!primaryList.length ||
              filter.variable === '' ||
              filter.granularity === '' ||
              (filter.showSecondary && (!secondaryList.length || filter.variable2 === '' || filter.granularity2 === ''))) :
            !primaryList.length
          }
          onClick={() => onSubmit(filter)}
        >
          Consultar
        </Button>

        <Button
          variant="contained"
          onClick={handleCancelFilterDialog}
          className={classes.btn}
        >
          Cancelar
        </Button>
      </Box>

      <Dialog
        className={classes.dialogStyle}
        open={showDevices}
        onClose={handleCloseDevicesDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
        disableScrollLock
      >
        <Snackbar
          open={notification.isOpen}
          autoHideDuration={2000}
          onClose={() => setNotification({ ...notification, isOpen: false })}
        >
          <Alert
            severity={notification.severity}
            onClose={() => setNotification(false)}
          >
            {notification.msg}
          </Alert>
        </Snackbar>

        <Box padding="5px">
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Selección de Dispositivos para gráfico{' '}
            {listType === 'primary' ? 'primario' : 'secundario'}
          </DialogTitle>
          <Box className={classes.dialogSubtitle}>
            Seleccionar uno o más elementos de la organización o un elemento y
            una etiqueta.
          </Box>

          <Box display="flex" className={classes.selectedItemsContainer}>
            {selectedNodes.map(node => (
              <Chip
                key={node.id}
                className={classes.wordCut}
                icon={<AccountTreeIcon />}
                label={node.nombre}
                clickable
                color="primary"
                onDelete={() => handleDeleteNodeSelection(node.id)}
              />
            ))}

            {selectedLabel.nombre ? (
              <Chip
                icon={<LabelIcon />}
                label={selectedLabel.nombre}
                clickable
                color="secondary"
                onDelete={handleDeleteLabelSelection}
              />
            ) : null}
          </Box>

          <Grid container display="block">
            <Grid item xs={12} md={6}>

              {blueBar()}

              <Box component="label" display="block" className={classes.label}>
                Organización
              </Box>

              <Box className={classes.containerNodes}>
                {nodes?.length === 0 && (
                  <h2
                    style={{
                      textAlign: 'center',
                      fontSize: '19px',
                      color: '#2586bc',
                      paddingTop: '20px',
                    }}
                  >
                    Usted no posee ninguna localización configurada en su
                    cuenta.<br></br>
                    Por favor, configure al menos una localización para poder
                    continuar.
                    <NavLink
                      className={classes.navLink}
                      to={`/configuracion-organizacion`}
                    >
                      [link]
                    </NavLink>
                  </h2>
                )}
                <List component="div" disablePadding dense>
                  {nodeList()}
                </List>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>

              {blueBar()}

              <Box component="label" display="block" className={classes.label}>
                Etiquetas
              </Box>

              <List
                className={classes.containerTags}
                component="div" disablePadding dense>
                <List
                  component="div"
                  disablePadding
                  dense
                  className={classes.scrollableContainer}
                >
                  {labelsList()}
                </List>
              </List>

            </Grid>
          </Grid>

          {blueBar()}

          {/* Botones */}
          <Box display="flex" justifyContent="center">
            <Button
              className={classes.devicesBtn}
              variant="contained"
              color="primary"
              onClick={handleSubmitSelectionItem}
              disabled={selectedNodes.length === 0}
            >
              {!editingId ? 'Agregar' : 'Editar'}
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseDevicesDialog}
              className={classes.devicesBtn}
            >
              Cancelar
            </Button>
          </Box>

        </Box>
      </Dialog>
    </>
  );
}
