
import { useState, useEffect } from 'react';
import {
    Box, Stepper, StepLabel, Step, Tooltip, makeStyles, TextField,
    FormControl, Select, MenuItem, FormControlLabel, Switch, FormGroup
} from '@material-ui/core';
//Style
import clsx from 'clsx';
//Icons
import DoneIcon from '@material-ui/icons/Done';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { getCatalogo } from '../helpers';
import DatepickerSingle from '../../DatePicker/DatepickerSingle';
//Hooks
import useDeviceDetect from '../../../hooks/useDeviceDetect';

const useStyles = makeStyles(theme => ({
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
}));

const AlertFilterOptions = ({ alertState, setAlertState, stateDiagram, setDisabledButtonSave }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const { isMobile } = useDeviceDetect(990);
    const [activeStep, setActiveStep] = useState(0);
    const [frequencies, setFrequencies] = useState([]);
    const [notificationType, setNotificationType] = useState([]);
    //Estado Datepicker
    const [date, setDate] = useState({
        dateFrom: ''
    });
    //Steeps options.
    const steps = [
        'Nombre de la alerta',
        'Elija una frecuencia',
        'Active o desactive notificaciónes',
        'Tipo de notificación',
        'Elija una fecha y hora de inicio de alerta.'
    ]

    useEffect(() => {
        setDisabledButtonSave(false)
        let res = getCatalogo('ALERTS_VARIABLES', 'generic_data')
        setNotificationType(res?.noti_choices)
        setFrequencies(res?.alerts_frequencies ? res.alerts_frequencies : [])
        //Cargo el estado local de el formulario si ya existe.
        setAlertState(stateDiagram?.alertObject?.alert)
        //Funcion q transforma el formato de fecha para tomarlo el datePicker
        mergeDate(stateDiagram?.alertObject.alert?.next_run)
        let alertAux = stateDiagram?.alertObject?.alert
        handleSteppers(alertAux)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Objeto de fecha
        if (date?.dateFrom) {
            const dateAux = new Date(date?.dateFrom);
            // Función para agregar ceros a la izquierda si es necesario
            const addZero = (number) => number < 10 ? `0${number}` : number;
            // Extraer día, mes, año, hora, minutos y segundos
            const day = addZero(dateAux.getDate());
            const month = addZero(dateAux.getMonth() + 1); // Los meses en JavaScript son de 0 a 11
            const year = dateAux.getFullYear();
            const hours = addZero(dateAux.getHours());
            const minutes = addZero(dateAux.getMinutes());
            const seconds = addZero(dateAux.getSeconds());
            // Construir la fecha en el formato deseado
            const dateString = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            if (dateString) {
                setAlertState({
                    ...alertState,
                    next_run: dateString
                })
                let stateAuxAlert = {
                    ...alertState,
                    next_run: dateString
                }
                handleSteppers(stateAuxAlert)
            }
        }
        // eslint-disable-next-line
    }, [date])

    const mergeDate = (value) => {
        // Default to an empty string if value is null or undefined
        const dateInput = value;
        // Check if dateInput is a non-empty string and matches the expected format
        if (!dateInput || !/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/.test(dateInput)) {
            return '';
        }
        // Extract date and time
        const [date, time] = dateInput.split(' ');
        const [day, month, year] = date.split('/');
        const [hours, minutes, seconds] = time.split(':');
        // Create a Date object using the extracted values
        const transformedDate = new Date(year, month - 1, day, hours, minutes, seconds);
        // Check if the created date is valid
        if (isNaN(transformedDate.getTime())) {
            console.error("Invalid date after transformation. Returning an empty string.");
            setDate({
                dateFrom: '' // Pass an empty string if transformed date is invalid
            });
            return '';
        }
        // Set the date in the state (or wherever needed)
        setDate({
            dateFrom: transformedDate // Pass it as a Date object
        });
        // Return the transformed Date object
        return transformedDate;
    }

    //Handle q toma el estado configuracion Alerta.
    const handleChangeAlertSettings = (e) => {
        let value = e.target.value
        let name = e.target.name
        let type = e.target.type
        setDisabledButtonSave(false)
        setAlertState({
            ...alertState,
            [name]: type === 'checkbox' ? e.target.checked : value
        })
        let stateAuxAlert = {
            ...alertState,
            [name]: type === 'checkbox' ? e.target.checked : value
        }
        handleSteppers(stateAuxAlert)
    }

    const handleSteppers = (stateAuxAlert) => {
        if (stateAuxAlert?.name?.length > 0) {
            setActiveStep(1)
        }
        if (stateAuxAlert?.frequency?.length > 0) {
            setActiveStep(2)
        }
        if (stateAuxAlert?.type_notify?.length > 0) {
            setActiveStep(4)
        }
        if (stateAuxAlert?.next_run?.length > 0) {
            setActiveStep(5)
        }
    }

    const disabledSteppers = (name) => {
        const alertName = alertState?.name?.length > 0;
        const typeNotify = alertState?.type_notify?.length > 0;
        const frequency = alertState?.frequency?.length > 0;
        switch (name) {
            case 'frequency':
                return !alertName; // Deshabilitado si no hay 'alertName'
            case 'datePicker':
                return !(typeNotify && frequency && alertName); // Deshabilitado si no hay ambos
            case 'type_notify':
                return !frequency; // Deshabilitado si no hay 'frequency'
            default:
                return true; // Si el 'name' no coincide con ningún caso, por seguridad.
        }
    };

    return (
        <Box>
            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>  Configuración de Alerta.</h3>
            </Box >

            {/* STEPPERS */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{isMobile ? '' : label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box width={'100%'}>
                <Box m={1} >
                    {/* STEP 1 */}
                    <Box display='flex' alignItems='flex-start'  >

                        <Tooltip
                            title='Elija el nombre de la alarma.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: alertState?.name?.length > 0 })}  >
                                {alertState?.name?.length > 0 ? <DoneIcon style={{ padding: '2px' }} /> : 1}
                            </Box>
                        </Tooltip>

                        <Box width={'100%'}>
                            <Box className={classes.devicesLabel}>
                                Selecciona el nombre de su alarma.
                            </Box>
                            <TextField
                                style={{ width: '90%' }}
                                autoFocus
                                type='text'
                                name='name'
                                value={alertState?.name ? alertState?.name : ''}
                                onChange={handleChangeAlertSettings}
                                color='primary'
                            />
                        </Box>
                    </Box>

                    {blueLine}

                    {/* STEP 2 */}
                    {/* Selector de Componente */}
                    <Box display='flex' alignItems='flex-start' width={'100%'}>

                        <Tooltip
                            title='Elija la frecuencia.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: alertState?.frequency?.length > 0 })}  >
                                {alertState?.frequency?.length > 0 ? <DoneIcon style={{ padding: '2px' }} /> : 2}
                            </Box>
                        </Tooltip>

                        <Box width={'100%'}>
                            <Box className={classes.devicesLabel}>
                                Seleccione una frecuencia.
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name='frequency'
                                    value={frequencies.some(elem => elem.key === alertState?.frequency) ? alertState.frequency : ''}
                                    onChange={handleChangeAlertSettings}
                                    disabled={disabledSteppers('frequency')}
                                >
                                    {frequencies.map((elem, indx) => (
                                        <MenuItem
                                            key={indx}
                                            value={elem.key}
                                        >
                                            {`${elem.value}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    {blueLine}

                    {/* STEP 3 */}
                    <Box display='flex' alignItems='flex-start' width={'100%'}>
                        <Box width={'40px'}>
                            <Tooltip
                                title='Active o desactive notificaciones.'
                                arrow
                                placement='right'
                            >
                                <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 0 })} >
                                    {3}
                                </Box>
                            </Tooltip>
                        </Box>

                        <Box display='flex' alignItems='flex-start'>
                            <Box className={classes.devicesLabel}>
                                Notificaciones.
                            </Box>
                            <Box ml={6} >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='notify'
                                                // disabled={(general ? false : !disabledOption[elem.name]) || (elem.name === 'telegram_notification')}
                                                checked={alertState?.notify ? alertState?.notify : false}
                                                onChange={handleChangeAlertSettings}
                                                color='primary' />
                                        }
                                    />
                                </FormGroup>
                            </Box>
                        </Box>
                    </Box >

                    {blueLine}

                    {/* STEP 4 */}
                    {/* Selector de Componente */}
                    <Box display='flex' alignItems='flex-start' >
                        <Tooltip
                            title='Elija tipo de notificación.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 4 })}  >
                                {activeStep >= 4 ? <DoneIcon style={{ padding: '2px' }} /> : 4}
                            </Box>
                        </Tooltip>
                        <Box width={'100%'}>
                            <Box className={classes.devicesLabel}>
                                Tipo de notificacion.
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    disabled={disabledSteppers('type_notify')}
                                    name='type_notify'
                                    value={notificationType.some(elem => elem.key === alertState?.type_notify) ? alertState?.type_notify : ''}
                                    onChange={handleChangeAlertSettings}
                                >
                                    {notificationType?.map((elem, indx) => (
                                        <MenuItem
                                            key={indx}
                                            value={elem.key}
                                        >
                                            {elem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    {blueLine}

                    {/* STEP 5 */}
                    <Box display='flex' alignItems='flex-start' width={'100%'}>
                        <Tooltip
                            title='Elija una fecha y hora de inicio de alerta.'
                            arrow
                            placement='right'
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.tick]: activeStep >= 5 })}  >
                                {activeStep >= 5 ? <DoneIcon style={{ padding: '2px' }} /> : 5}
                            </Box>
                        </Tooltip>


                        <Box width={'100%'} >
                            <Box className={classes.devicesLabel}>
                                Próximo envío
                            </Box>
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                            >

                            </Box>
                        </Box>
                    </Box>

                </Box >
            </Box>
            <DatepickerSingle
                disabledDatePicker={disabledSteppers('datePicker')}
                startDate={date.dateFrom}
                setDate={setDate}
                date={date}
            />
        </Box >
    )
}
export default AlertFilterOptions