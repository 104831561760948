
import { useEffect, useState, } from 'react';
import {
    Box, MenuItem,
    makeStyles, Select, FormControl,
    TextField, InputAdornment
} from '@material-ui/core';
//Helpers.
import { blueBar } from '../../../helpers/common'

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '600px',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.primary.dark,
    },
    content: {
        maxWidth: '100%',            // Asegura que el contenido no se expanda más allá del ancho disponible
        fontSize: 14,                // Tamaño de fuente
        color: '#5c5d5d',            // Color del texto
        wordWrap: 'break-word',      // Rompe las palabras largas para ajustarlas al contenedor
        overflowWrap: 'break-word',  // Rompe las palabras largas en el contenido
        whiteSpace: 'normal',        // Permite que el texto se apile en lugar de desbordar
        overflowX: 'auto',           // Habilita el scroll horizontal si el contenido excede el ancho
        overflowY: 'hidden',         // Deshabilita el scroll vertical si no lo necesitas
        // Espaciado inferior    
        // Personalización de la barra de desplazamiento
        '&::-webkit-scrollbar': {
            height: '9px',
            background: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#beb1b18f',
        },
        '&::-webkit-scrollbar-track': {
            background: '#dfdfe957',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },   //
    }
}));

const DynamicFilterOptions = ({ blockNodeSettings, varState, setVarState, typeBlock, disableEditing }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({})
    const [blockNodeSettingsLocalState, setBlockNodeSettingsLocalState] = useState(blockNodeSettings)
    const BOOL_OPTIONS = [
        {
            name: 'Verdadero',
            key: 'true'
        },
        {
            name: 'Falso',
            key: 'false'
        },
    ]

    const PID_FORMATO = [
        {
            name: 'Estandar',
            key: '0'
        },
        {
            name: 'Paralelo',
            key: '1'
        },
    ]

    //Se encarga de cargar los valores de los input segun el caso del bloque
    useEffect(() => {
        funciónCaseDinamicUpdateComponentValues()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let objAux = { ...stateForm }
        // valido los datos q son centisegundos
        if (objAux?.p) {
            objAux.p = transformUnit(objAux?.p)
        }
        setVarState({
            ...varState,
            a: objAux
        })
        // eslint-disable-next-line
    }, [stateForm])

    function filterKeys(attributes, keysToRemove) {
        return attributes.filter(attr => !keysToRemove.includes(attr.key));
    }

    const handleChange = (e, label) => {
        let { value, name } = e.target;
        let objAux = { ...stateForm }
        if (typeBlock === 'PID') {
            if (label === 'Formato') {
                let filteredAttributes = blockNodeSettingsLocalState?.atributes_keys
                let keysToRemove = ['kp', 'ki', 'kd', 'tf']
                if (value === '0') {
                    // ('estandar')
                    objAux = {
                        ...objAux,
                        kc: '',
                        ti: '',
                        td: '',
                        a: ''
                    }
                }
                else {
                    // ('paralelo')
                    keysToRemove = ['kc', 'ti', 'td', 'a'];
                    objAux = {
                        ...objAux,
                        kp: '',
                        ki: '',
                        kd: '',
                        tf: ''
                    }
                }
                filteredAttributes = filterKeys(blockNodeSettings?.atributes_keys, keysToRemove);
                setBlockNodeSettingsLocalState({
                    ...blockNodeSettingsLocalState,
                    atributes_keys: filteredAttributes
                })
            }
        }
        setStateForm({
            ...objAux,
            [name]: value
        })
    }

    //Devuelve el tipo de valor q es en el input segun la propiedad.
    const returnTypeInput = (name) => {
        if (name === 'Nombre' || name === 'Unidad') {
            return 'text'
        }
        return 'number'
    }

    const returnLabelInput = (nameAux) => {
        if (nameAux === 'Periodo de trabajo del PID') {
            return 'Segundos'
        }
    }

    // Convierte a entero sin signo de 32 bits
    function transformUnit(value) {
        let resultado = value * 100;
        resultado = Math.round(resultado);
        return resultado.toString()
    }

    const funciónCaseDinamicUpdateComponentValues = () => {
        if (varState?.a) {
            let objAux = { ...varState?.a }
            if (objAux?.p) {
                objAux.p = objAux.p /= 100;
            }
            setStateForm(objAux)
            //PID con valores pre cargados.
            if (typeBlock === 'PID') {
                let keysToRemove = ['kp', 'ki', 'kd', 'tf'];
                if (varState?.f !== '0') {
                    keysToRemove = ['kc', 'ti', 'td', 'a'];
                }
                const filteredAttributes = filterKeys(blockNodeSettingsLocalState?.atributes_keys, keysToRemove);
                setBlockNodeSettingsLocalState({
                    ...blockNodeSettingsLocalState,
                    atributes_keys: filteredAttributes
                })
            }
        }
    }

    const funciónCaseDinamicInputs = (element) => {
        if (element?.name === 'Inversión de lógica') {
            return (
                <Box mt={2}>
                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                        <Box className={classes.devicesLabel}>
                            {element.name}
                        </Box>
                    </Box>
                    <Box width={'100%'}>
                        <FormControl fullWidth>
                            <Select
                                disabled={disableEditing}
                                name={element.key}
                                value={stateForm?.[element.key] ? stateForm?.[element.key] : ''}
                                onChange={e => handleChange(e)}
                            >
                                {BOOL_OPTIONS.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.key}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            )
        }
        if (element?.name === 'Formato') {
            return (
                <Box mt={2}>
                    {/* STEP 1 */}
                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                        <Box className={classes.devicesLabel}>
                            {element.name}
                        </Box>
                    </Box>
                    <Box width={'100%'}>
                        <FormControl fullWidth>
                            <Select
                                disabled={disableEditing}
                                name={element.key}
                                value={stateForm?.[element.key] ? stateForm?.[element.key] : ''}
                                onChange={e => handleChange(e, element.name)}
                            >
                                {PID_FORMATO.map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem.key}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            )
        }
        return (
            < Box width={'100%'} mt={2} className={classes.input}>
                <TextField
                    disabled={disableEditing}
                    fullWidth
                    type={returnTypeInput(element.name, element?.key)}
                    name={element.key}
                    label={element.name}
                    size='small'
                    variant='outlined'
                    value={stateForm?.[element.key] ? stateForm?.[element.key] : ''}
                    onChange={e => handleChange(e, element.name)}
                    color='primary'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>{returnLabelInput(element.name)}</InputAdornment>,
                    }}
                />
            </Box>
        )
    }

    return (
        <Box>
            {blueLine}
            <>
                {
                    (Array.isArray(blockNodeSettingsLocalState?.atributes_keys) ? blockNodeSettingsLocalState?.atributes_keys : []).map((element, indx) => {
                        return (
                            <Box key={indx} >
                                {funciónCaseDinamicInputs(element)}
                            </Box>
                        )
                    })
                }
            </>
        </Box >
    )
}
export default DynamicFilterOptions





