
import { useState } from 'react';
import {
    Box, makeStyles, FormControl, Select, MenuItem
} from '@material-ui/core';
//Helpers.
import { blueBar } from '../../../helpers/common'

const useStyles = makeStyles(theme => ({
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.primary.dark,
    },
    content: {
        maxWidth: '100%',            // Asegura que el contenido no se expanda más allá del ancho disponible
        fontSize: 14,                // Tamaño de fuente
        color: '#5c5d5d',            // Color del texto
        wordWrap: 'break-word',      // Rompe las palabras largas para ajustarlas al contenedor
        overflowWrap: 'break-word',  // Rompe las palabras largas en el contenido
        whiteSpace: 'normal',        // Permite que el texto se apile en lugar de desbordar
        overflowX: 'auto',           // Habilita el scroll horizontal si el contenido excede el ancho
        overflowY: 'hidden',         // Deshabilita el scroll vertical si no lo necesitas
        // Espaciado inferior    
        // Personalización de la barra de desplazamiento
        '&::-webkit-scrollbar': {
            height: '9px',
            background: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#beb1b18f',
        },
        '&::-webkit-scrollbar-track': {
            background: '#dfdfe957',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
    }
}));

const InputsFilterOptions = ({ id, handleSourceQuantity, setDisabledButtonSave, disableEditing, setLastValueInputsSettings }) => {
    const blueLine = blueBar()
    const classes = useStyles();

    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({
        quantity: handleSourceQuantity[handleSourceQuantity.length - 1],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            setDisabledButtonSave(false)
        }
        setStateForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        //Devuelve un array segun el value ejemplo: value=4 [1,2,3,4]
        const newSelectedNumber = Array.from({ length: parseInt(value) }, (_, index) => index + 1);
        let blockId = id
        setLastValueInputsSettings(
            {
                value: value,
                blockId: blockId,
                newSelectedNumber: newSelectedNumber
            }
        )
    };

    return (
        <Box>
            {blueLine}
            <Box ml={1} >
                {/* STEP 2 */}
                <Box display={'flex'}>
                    <Box width={'100%'} >
                        <Box className={classes.devicesLabel}>
                            Cantidad de entradas.
                        </Box>
                        <FormControl fullWidth>
                            {/* <InputLabel id="variableLabel">Seleccione Variable</InputLabel> */}
                            <Select
                                disabled={disableEditing}
                                name='quantity'
                                value={stateForm.quantity}
                                onChange={handleChange}
                            >
                                {[2, 3, 4, 5].map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem}
                                    >
                                        {elem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
export default InputsFilterOptions