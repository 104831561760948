import { Box, Typography } from "@mui/material";

export default function TotalModalPay({ products, planType, selectedPlan, credit, annualMultiplier }) {
    const MONTHLY = 1;

    const getTotal = () => {
        let total = products.reduce((acc, product) => acc + product.newPrice * product.quantity, 0);
        let baseCost = getPlanPrice();
        return (((Number(baseCost) + total)) - Number(credit)).toFixed(2);
    };

    const getPlanPrice = () => {
        return planType === MONTHLY ? selectedPlan?.costo_mensual : selectedPlan?.costo_mensual * annualMultiplier;
    }

    const getTotalPerks = () => {
        let total = products.reduce((acc, product) => acc + product.newPrice * product.quantity, 0);
        return total.toFixed(2);
    };


    return (
        <>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Typography variant="h6" fontWeight="bold">
                    Total:
                </Typography>
                <Typography variant="h6" fontWeight="bold" color="primary">
                    ${getTotal()} ARS
                </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body1">Precio del plan:</Typography>
                <Typography variant="body1" fontWeight="bold">
                    ${getPlanPrice()} ARS
                </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Total de perks:</Typography>
                <Typography variant="body1" fontWeight="bold">
                    ${getTotalPerks()} ARS
                </Typography>
            </Box>

            
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">Crédito aplicado:</Typography>
                <Typography variant="body1" fontWeight="bold" color="error">
                    -${credit} ARS
                </Typography>
            </Box>
            
        </>
    )
}