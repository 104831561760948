let elementNode = {
    parent: null,
    type: '',
    childrens: [
    ]
}

// Obtiene el catalogo
export const getCatalogo = (seccion, property, keyVar) => {
    let nameVarLocal = 'UI_ROLES'
    let VAR = seccion
    if (VAR === 'CREATOR') {
        VAR = 'Automate-24V-4M'
    }
    let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal))?.sections[VAR]
    if (VARS_CATALOGO) {
        if (property) {
            VARS_CATALOGO = VARS_CATALOGO[property]
        }
        if (keyVar) {
            let filterBar = VARS_CATALOGO.find(e => e.key === keyVar)
            return filterBar
        }
        else {
            return VARS_CATALOGO ? VARS_CATALOGO : []
        }
    }
};

//Filtra la configuracion de cualquier bloque existente en catalogo.
export const getSettingBlock = (type) => {
    let res1 = getCatalogo('ALERTS_VARIABLES', 'generic_data')
    let res2 = getCatalogo('Automate-24V-4M', 'generic_data')
    let arryTotal = []
    if (res1 && res2) {
        arryTotal = [...res1['type_blocks'], ...res2['type_blocks']]
    }
    else {
        if (res2) {
            arryTotal = [...res2['type_blocks']]
        }
        if (res1) {
            arryTotal = [...res1['type_blocks']]
        }
    }

    if (type) {
        const arrayFilterSameItem = [...new Set(arryTotal)];
        let filterElement = arrayFilterSameItem.find(e => e.key === type)
        return {
            ...filterElement
        }
    }
    else {
        return arryTotal
    }
};

//Evalua la configuracion inicial de los valores del formulario del bloque y devuelve el objeto a:{ }
function generateFilteredObject(arr) {
    if (Object.keys(arr).length === 0) {
        return {}
    } else {
        return arr?.reduce((acc, item) => {
            if (item.hasOwnProperty('default')) {
                acc[item.key] = item.default;
            }
            return acc;
        }, {});
    }
}

export const checkPropertyConditions = (node) => {
    const copy = JSON.parse(JSON.stringify(node));
    let res = getSettingBlock(copy?.type)
    copy.a = generateFilteredObject(res?.atributes_keys)
    return copy
}

//Funcion q filtra las propiedades de los nodos q no queiro enviar al back.
function copyWithoutProperties(object, properties) {
    const copy = { ...object }; // Creating a shallow copy of the original object
    properties.forEach(property => {
        if (copy.hasOwnProperty(property)) {
            delete copy[property];
        }
    });
    return copy;
}

//Funcion Busca un nodo.
export const findNodeCreateTree = (nodes, edges, id, targetInputAux) => {
    let targetNode = nodes.find(e => e.id === id)

    targetNode = copyWithoutProperties(targetNode, ['positionAbsolute', 'data', 'selected', 'height', 'dragging', 'width'])
    let newNode = {
        ...targetNode,
        targetInputAux: targetInputAux,
        parent: null,
        id: targetNode.id,
        idAux: targetNode.id,
        type: targetNode.type,
        values: targetNode?.values ? targetNode?.values : 'SECCION-VARIABLE-GRANULARIDAD-RANGO',
        nodes: targetNode?.nodes ? targetNode?.nodes : [],
        variable_operation: targetNode?.variable_operation ? targetNode?.variable_operation : '',
        childrens: createChildren(targetNode, nodes, edges).childrens
    }
    return newNode
}

//Mueve siempre a la primera posicion al bloque con el inputTarget letra r para respetar el orden de los comparadores. donde 'r' es izquierda y 'l' derecha.
function moveToFirstPosition(childrens) {
    // Encuentra el índice del elemento con targetInputAux igual a "l"
    const index = childrens.findIndex(item => item?.targetInputAux === "l");
    // Si el elemento existe y no está en la posición 0, muévelo al inicio
    if (index > 0) {
        const [element] = childrens.splice(index, 1); // Elimina el elemento de su posición actual
        childrens.unshift(element); // Lo coloca al inicio del childrensay
    }
    return childrens;
}

//Funcion q arma los hijos de cada nodo.
export const createChildren = (element, nodes, edges) => {
    let node = {
        ...element,
        elementNode
    }
    let indx = element.id
    let childrens = []
    node.type = element.type
    edges.forEach((elem) => {
        if (elem.target === indx) {
            let targetElement = elem.source
            if (targetElement) {
                childrens.push(findNodeCreateTree(nodes, edges, targetElement, elem.targetHandle))
            }
        }
    })
    node.childrens = moveToFirstPosition(childrens)
    return node
}

//Funcion q arma el arbol.
export const createTree = (nodes, edges) => {
    let tree = {}
    nodes.forEach((elem, index) => {
        if (elem.type === 'ALERT') {
            tree = createChildren(elem, nodes, edges)
        }
    })
    if (tree?.childrens?.length > 0) {
        return tree?.childrens[0]
    }
    else {
        return []
    }
    // return tree?.childrens[0] ? tree?.childrens[0] : []
}

export const findType = (id, nodes) => {
    let filter = nodes?.find(e => e?.id === id)
    return filter?.type ? filter.type : ''
}

export const findNode = (id, nodes) => {
    let filter = nodes?.find(e => e?.id === id)
    return filter ? filter : {}
}



const lokingForSamePoint = (target, edges) => {
    let array = edges
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.target === target.target) {
            if (target.sourceHandle === element.sourceHandle) {
                if (target.targetHandle === element.targetHandle) {
                    return true
                }
            }
        }
    }
    return false
}

export const validatorEdges = (params, nodes, edges) => {
    let source = params?.target
    let target = params?.source
    let typeSource = findType(source, nodes)
    let typeTarget = findType(target, nodes)
    let validalitgSamePoint = lokingForSamePoint(params, edges)
    if (validalitgSamePoint) {
        return { msg: 'No es posible establecer una conexión a una entrada que ya posee una.' };
    }
    const booleanBlocksA = ['AND', 'OR', 'NOT', 'LESS', 'LEQ', 'GREATER', 'GEQ', 'EQ'];
    const numericBlocksA = ['LESS', 'LEQ', 'GREATER', 'GEQ', 'EQ'];
    if (booleanBlocksA.includes(typeTarget) && numericBlocksA.includes(typeSource)) {
        return { msg: 'No es posible establecer una conexión entre un bloque que devuelve un valor booleano y un bloque que espera un número.' };
    }
    const booleanBlocksB = ['VARIABLE'];
    const numericBlocksB = ['AND', 'OR', 'NOT'];
    if (booleanBlocksB.includes(typeTarget) && numericBlocksB.includes(typeSource)) {
        return { msg: 'No es posible establecer una conexión entre un bloque que devuelve un valor numerico y un bloque que espera un booleano.' };
    }
    const listDisabledConectionAlertNode = ['VARIABLE']
    if (typeSource === 'ALERT') {
        //Si alguno de los target nodes esta en la lista no permite conectarlos.
        let disabled = listDisabledConectionAlertNode.includes(typeTarget)
        if (disabled) {
            return { msg: 'Está conectando nodos incompatibles.' }
        }
    }
    return true
}

export const editarElementoPorId = (arbol, id, newProperties) => {
    let arbolAux = { ...arbol }
    // Función recursiva para buscar y editar el elemento por su ID
    function buscarYEditar(elemento) {
        // Verificar si el elemento actual tiene el ID buscado
        if (elemento.id === id) {
            // Si encuentra el elemento por su ID, lo edita
            for (let clave in newProperties) {
                if (newProperties.hasOwnProperty(clave)) {
                    elemento[clave] = newProperties[clave]
                }
            }
        }
        else if (elemento.childrens && elemento.childrens.length > 0) {
            // Si el elemento tiene hijos, los recorre de manera recursiva
            elemento.childrens.forEach(buscarYEditar);
        }
    }
    // Llama a la función para buscar y editar el elemento
    arbolAux.childrens.forEach(buscarYEditar);
    return arbolAux
}

export const spanishLocaleForCron = {
    everyText: 'Cada',
    emptyMonths: 'Ningún mes seleccionado',
    emptyMonthDays: 'Ningún día seleccionado',
    emptyWeekDays: 'Ningún día de la semana seleccionado',
    emptyHours: 'Ninguna hora seleccionada',
    emptyMinutes: 'Ningún minuto seleccionado',
    emptySeconds: 'Ningún segundo seleccionado',
    emptyYears: 'Ningún año seleccionado',
    emptyMinutesForHourPeriod:
        'Ningún minuto seleccionado para el período de horas',
    yearOption: 'Año',
    monthOption: 'Mes',
    weekOption: 'Semana',
    dayOption: 'Día',
    hourOption: 'Hora',
    minuteOption: 'Minuto',
    secondOption: 'Segundo',
    rebootOption: 'Reiniciar',
    prefixPeriod: 'Cada',
    prefixMonths: 'En',
    prefixMonthDays: 'El',
    prefixWeekDays: 'En',
    prefixWeekDaysForMonthAndYearPeriod: 'En',
    prefixHours: 'A las',
    prefixMinutes: 'A los',
    prefixMinutesForHourPeriod: 'En el minuto',
    prefixSeconds: 'En el segundo',
    prefixSecondsForMinutePeriod: 'En el segundo',
    suffixMinutes: 'minuto(s)',
    suffixHours: 'hora(s)',
    suffixDays: 'día(s)',
    suffixWeeks: 'semana(s)',
    suffixMonths: 'mes(es)',
    suffixYears: 'año(s)',
    errorInvalidCron: 'Expresión cron inválida',
    months: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ],
    weekDays: [
        'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',
    ],
    altMonths: [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
    ],
    altWeekDays: [
        'Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb',
    ],
    clearButtonText: 'Limpiar',
};

