import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_es_ES from '@amcharts/amcharts4/lang/es_ES';
import { formattingSeries } from '../Instant/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        '@media (max-width: 700px)': {
            margin: theme.spacing(0),
        },
        display: 'flex',
        justifyContent: 'center'
    },
}));

export default function DualChartAdvanced({
    chartData,
    tz
}) {
    const classes = useStyles();

    useEffect(() => {
        const chartsContainer = document.getElementById('instantaneos-avanzado');
        const charts = Object.groupBy(chartData, ({ groupIndex }) => groupIndex);
        const chartKeys = Object.keys(charts);
        const chartCount = chartKeys.length;
        const baseHeight = 700;
        const heightPerChart = chartCount > 1 ? `${450}px` : `${baseHeight}px`;
        const seriesUsed = []

        for (const chartKey in charts) {
            const chartDataSet = charts[chartKey];
            // Crear un contenedor único para cada gráfico
            const chartDivId = `chart-${chartKey}`;
            const chartDiv = document.createElement('div');
            chartDiv.id = chartDivId;
            chartDiv.style.width = '100%';
            chartDiv.style.height = heightPerChart;
            chartDiv.style.marginRight = '50px'
            chartsContainer.appendChild(chartDiv);

            // Crea un nuevo gráfico
            const chart = am4core.create(chartDivId, am4charts.XYChart);
            // Configuración básica del gráfico
            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.baseInterval = {
                timeUnit: 'minute',
                count: 1,
            };
            dateAxis.tooltipDateFormat = 'dd/MM/yyyy HH:mm';

            chart.fontSize = 11;
            chart.language.locale = am4lang_es_ES;
            chart.dateFormatter.timezone = tz;
            chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ssZ';

            chart.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX.parent = chart.bottomAxesContainer;
            chart.scrollbarX.strokeWidth = 1;
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.strokeWidth = 0;
            chart.strokeWidth = 2;

            chart.legend = new am4charts.Legend();

            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.items = [
                {
                    label: '...',
                    menu: [
                        { type: 'pdf', label: 'PDF' },
                        { type: 'csv', label: 'CSV' },
                        { type: 'xlsx', label: 'XLSX' },
                        { type: 'print', label: 'Imprimir' },
                    ],
                },
            ];

            chartDataSet.forEach((dataset, indexDataset) => {
                updateChart(chart, dataset, indexDataset);
            });

            // Deshabilitar el logo si existe
            if (chart?.logo) {
                chart.logo.disabled = true;
            }
        }

        //Funcion que segun la granularidad q selecciono devuelvo una configuracion para la separacion entre puntos,
        // en este caso es 3 veces mas su valor no unir puntos.
        function gapCalc(granularity) {
            let res = 20
            if (granularity) {
                res = Number(granularity) * 4
            }
            return res
        }

        function updateChart(chart, dataset, indexDataset) {
            let alias = ""
            switch (dataset.variable) {
                case 'TENSION_POR_FASE':
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let color = dataset.color
                        const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
                        const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
                        alias = `Fase ${phaseName} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset.data?.[`V_${phaseName}`],
                            '#.##V',
                            gapCalc(dataset.granularity),
                            color + colorOffset,
                            alias,
                            'Tensión por fase',
                            indexDataset
                        );
                    }
                    break;
                case 'CORRIENTE_POR_FASE':
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let color = dataset.color
                        const phaseName = i === 0 ? 'R' : i === 1 ? 'S' : 'T';
                        const colorOffset = i === 0 ? '' : i === 1 ? '60' : '90';
                        let alias = `Fase ${phaseName} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset?.data?.[`I_${phaseName}`],
                            `#.##'A`,
                            gapCalc(dataset.granularity),
                            color + colorOffset,
                            alias,
                            'Corriente por fase',
                            indexDataset
                        );
                    }
                    break;
                case 'POTENCIA_ACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.P,
                        `#.##aW`,
                        gapCalc(dataset.granularity),
                        dataset.color,
                        dataset.alias,
                        'Potencia activa total',
                        indexDataset
                    );
                    break;
                case 'POTENCIA_ACTIVA_POR_FASE':
                    let nameLabelTooltipsP = ''
                    let colorOffsetP = ''
                    if (dataset.medidores[0]) {
                        dataset.medidores[0].count_phases += 1;
                    }
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let color = dataset.color
                        let phaseName = ''
                        if (i === 0) {
                            phaseName = 'P_R'
                            nameLabelTooltipsP = 'Fase R'
                            colorOffsetP = '60'
                        }
                        if (i === 1) {
                            phaseName = 'P_S'
                            nameLabelTooltipsP = 'Fase S'
                            colorOffsetP = '65'
                        }
                        if (i === 2) {
                            phaseName = 'P_T'
                            nameLabelTooltipsP = 'Fase T'
                            colorOffsetP = '70'
                        }
                        if (i === 3) {
                            phaseName = 'P'
                            nameLabelTooltipsP = 'Total'
                            colorOffsetP = '90'
                        }
                        alias = `${nameLabelTooltipsP} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset.data?.[`${phaseName}`],
                            `#.##aW`,
                            gapCalc(dataset.granularity),
                            color + colorOffsetP,
                            alias,
                            'Potencia activa por fase',
                            indexDataset
                        );
                    }
                    break;
                case 'POTENCIA_REACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset.data?.Q,
                        `#.##aVAr`,
                        gapCalc(dataset.granularity),
                        dataset.color,
                        dataset.alias,
                        'Potencia reactiva total',
                        indexDataset
                    );
                    break;
                case 'POTENCIA_REACTIVA_POR_FASE':
                    let nameLabelTooltipsQ = ''
                    let colorOffsetQ = ''
                    if (dataset.medidores[0]) {
                        dataset.medidores[0].count_phases += 1;
                    }
                    for (let i = 0; i < dataset.medidores[0]?.count_phases; i++) {
                        let phaseName = ''
                        let color = dataset.color
                        if (i === 0) {
                            phaseName = 'Q_R'
                            nameLabelTooltipsQ = 'Fase R'
                            colorOffsetQ = '60'
                        }
                        if (i === 1) {
                            phaseName = 'Q_S'
                            nameLabelTooltipsQ = 'Fase S'
                            colorOffsetQ = '65'
                        }
                        if (i === 2) {
                            phaseName = 'Q_T'
                            nameLabelTooltipsQ = 'Fase T'
                            colorOffsetQ = '70'
                        }
                        if (i === 3) {
                            phaseName = 'Q'
                            nameLabelTooltipsQ = 'Total'
                            colorOffsetQ = '90'
                        }
                        alias = `${nameLabelTooltipsQ} (${dataset.alias})`;
                        addSeriesAndAxis(
                            chart,
                            dataset.data?.[`${phaseName}`],
                            `#.##aVAr`,
                            gapCalc(dataset.granularity),
                            color + colorOffsetQ,
                            alias,
                            'Potencia reactiva por fase',
                            indexDataset
                        );
                    }
                    break;
                case 'POTENCIA_ADQUIRIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.KW.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aW',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Potencia adquirida total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_TOTAL_POR_HORA':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energia total por hora',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.KWH.map(item => ({ ...item, valor: item.valor * 1000 })),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energia activa total',
                        indexDataset
                    );
                    break;
                case 'TEMPERATURA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.TEMP,
                        `#.## °C`,
                        180,
                        dataset.color,
                        dataset.alias,
                        'Temperatura',
                        indexDataset
                    );
                    break;
                case 'PRESION_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.PRESSURE,
                        `# hPa`,
                        180,
                        dataset.color,
                        dataset.alias,
                        'Presión',
                        indexDataset
                    );
                    break;
                case 'HUMEDAD_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        dataset?.data?.HUMIDITY,
                        `#.## '%`,
                        180,
                        dataset.color,
                        dataset.alias,
                        'Humedad Total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_IMPORTADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.KWH_I, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energía activa importada Total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_EXPORTADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.KWH_E, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energía activa exportada total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_GENERADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.KWH_G, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energía activa generada total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_CONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.KWH_C, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energía activa consumida total',
                        indexDataset
                    );
                    break;
                case 'ENERGIA_ACTIVA_AUTOCONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.KWH_AC, dataset.variable, 'INSTANTANEOS'),
                        '#.##aWh',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Energía activa autoconsumida total',
                        indexDataset
                    );
                    break;
                case 'POTENCIA_ACTIVA_GENERADA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.P_G, dataset.variable, 'INSTANTANEOS'),
                        '#.##aW',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Potencia activa generada total',
                        indexDataset
                    );
                    break;
                case 'POTENCIA_ACTIVA_CONSUMIDA_TOTAL':
                    addSeriesAndAxis(
                        chart,
                        formattingSeries(dataset?.data?.P_C, dataset.variable, 'INSTANTANEOS'),
                        '#.##aW',
                        180,
                        dataset.color,
                        dataset.alias,
                        'Potencia activa consumida total',
                        indexDataset
                    );
                    break;
                default:
                    for (const key in dataset?.data) {
                        addSeriesAndAxis(
                            chart,
                            formattingSeries(dataset?.data?.[key], dataset.variable, 'INSTANTANEOS'),
                            `#a${dataset?.base_unit}`,
                            180,
                            dataset.color,
                            dataset.alias,
                            dataset?.base_unit,
                            indexDataset
                        );
                    }
                    break;
            }
        }

        function addSeriesAndAxis(chart, data, format, gap = 20, color, alias, title, indexDataset) {
            if (!data)
                return;
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

            seriesUsed.push({ format, title })

            const series = chart.series.push(new am4charts.LineSeries());
            const numberFormatter = new am4core.NumberFormatter();
            numberFormatter.numberFormat = format;
            series.connect = false;
            series.numberFormatter = numberFormatter;


            if (indexDataset > 0) {
                let yAxis = series.yAxis
                yAxis.renderer.grid.template.disabled = true;
            }
            //valido si se repiten los formatos para reutilizar mismo eje
            seriesUsed.forEach((serie) => {
                let formatExistentMoreThanOnce = seriesUsed.filter((serie) => serie.format === format).length > 1;
                let existentFormat = chart.yAxes.values.find((f) => f.numberFormatter._numberFormat === format);

                if (!formatExistentMoreThanOnce) {

                    // Si no existe, crear un nuevo eje                   
                    valueAxis.renderer.line.strokeOpacity = 1;
                    valueAxis.renderer.line.strokeWidth = 2;
                    valueAxis.title.text = title
                    series.yAxis = valueAxis;
                    valueAxis.numberFormatter = numberFormatter;
                    valueAxis.disabled = false;
                } else {
                    valueAxis.disabled = true
                    series.yAxis = existentFormat;
                }
            });


            series.autoGapCount = gap;
            series.dataFields.valueY = 'valor';
            series.dataFields.dateX = 'timestamp';
            series.data = data;
            series.name = alias;
            series.stroke = am4core.color(color);
            series.tooltipText = `{valueY} - ${alias}`;
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color(color);
            const circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.fill = am4core.color('#fff');
            series.minBulletDistance = 15;

            chart.scrollbarX.series.push(series);
        }
        return () => {
            am4core.disposeAllCharts();
        };
        // eslint-disable-next-line
    }, [chartData]);

    return (
        <Box className={classes.root}>
            <div
                id={`instantaneos-avanzado`}
                style={{ width: '100%', height: '100%', marginTop: '25px' }}
            />
        </Box>
    );
}
