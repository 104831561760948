
import { useEffect, useState, } from 'react';

import {
    Box, MenuItem,
    makeStyles, Select, FormControl,
    TextField,
} from '@material-ui/core';
//Helpers.
import { blueBar } from '../../../helpers/common'
//Textos 
import { MBS_OPTIONS_TR, MBS_OPTIONS_TD, MBS_OPTIONS_ENDIANNES, MBR_OPTIONS_INTERFAZ } from './helperDescriptionText';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '600px',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
    content: {
        maxWidth: '100%',            // Asegura que el contenido no se expanda más allá del ancho disponible
        fontSize: 14,                // Tamaño de fuente
        color: '#5c5d5d',            // Color del texto
        wordWrap: 'break-word',      // Rompe las palabras largas para ajustarlas al contenedor
        overflowWrap: 'break-word',  // Rompe las palabras largas en el contenido
        whiteSpace: 'normal',        // Permite que el texto se apile en lugar de desbordar
        overflowX: 'auto',           // Habilita el scroll horizontal si el contenido excede el ancho
        overflowY: 'hidden',         // Deshabilita el scroll vertical si no lo necesitas     
        '&::-webkit-scrollbar': {
            height: '9px',
            background: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#beb1b18f',
        },
        '&::-webkit-scrollbar-track': {
            background: '#dfdfe957',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },   //
    }
}));

const MbrFilterOptions = ({ varState, setVarState,  disableEditing }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({})

    useEffect(() => {
        if (varState?.a) {
            setStateForm(varState?.a)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setVarState({
            ...varState,
            a: stateForm
        })
        // eslint-disable-next-line
    }, [stateForm])

    const handleChange = (e) => {
        let { value, name } = e.target;
        setStateForm({
            ...stateForm,
            [name]: value
        })

    }

    const validateShowInput = (label) => {
        if (label === 'Endianness') {
            if (stateForm?.d === '0' || stateForm?.d === '1') {
                return false
            }
        }
        if (label === 'IP') {
            if (stateForm?.i !== '0') {
                return false
            }
        }
        if (label === 'Puerto') {
            if (stateForm?.i !== '0') {
                return false
            }
        }
        if (label === 'ID') {
            if (stateForm?.i === '0') {
                return false
            }
        }
        return true
    }

    return (
        <Box>       

            {blueLine}

            {/* STEP 1 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Tipo de registro
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='r'
                            value={stateForm?.r ? stateForm?.r : ''}
                            onChange={handleChange}
                            disabled={disableEditing}
                        >
                            {MBS_OPTIONS_TR?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 2 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Address
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                disabled={disableEditing}
                                fullWidth
                                type={"number"}
                                name='a'
                                size='small'
                                variant='outlined'
                                value={stateForm?.a ? stateForm?.a : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 3 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Tipo de dato
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='d'
                            value={stateForm?.d ? stateForm?.d : ''}
                            onChange={handleChange}
                            disabled={disableEditing}
                        >
                            {MBS_OPTIONS_TD?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 4 */}
            {validateShowInput('Endianness') &&
                <>
                    <Box display={'flex'} >
                        <Box width={'100%'} >
                            <Box className={classes.devicesLabel}>
                                Endianness
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name='e'
                                    value={stateForm?.e ? stateForm?.e : ''}
                                    onChange={handleChange}
                                    disabled={disableEditing}
                                >
                                    {MBS_OPTIONS_ENDIANNES?.map((elem, indx) => (
                                        <MenuItem
                                            key={elem.value}
                                            value={elem.key}
                                        >
                                            {elem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    {blueLine}
                </>
            }

            {/* STEP 5 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Ganancia
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                disabled={disableEditing}
                                fullWidth
                                type={"number"}
                                name='g'
                                // label={'Agregue un valor'}
                                size='small'
                                variant='outlined'
                                value={stateForm?.g ? stateForm?.g : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 6 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Offset
                    </Box>
                    <Box >
                        <Box className={classes.input} >
                            <TextField
                                disabled={disableEditing}
                                fullWidth
                                type={"number"}
                                name='o'
                                // label={'Agregue un valor'}
                                size='small'
                                variant='outlined'
                                value={stateForm?.o ? stateForm?.o : ''}
                                onChange={handleChange}
                                color='primary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {blueLine}

            {/* STEP 7 */}
            <Box display={'flex'} >
                <Box width={'100%'} >
                    <Box className={classes.devicesLabel}>
                        Interfaz
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            name='i'
                            value={stateForm?.i ? stateForm?.i : ''}
                            onChange={handleChange}
                            disabled={disableEditing}
                        >
                            {MBR_OPTIONS_INTERFAZ?.map((elem, indx) => (
                                <MenuItem
                                    key={elem.value}
                                    value={elem.key}
                                >
                                    {elem.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>


            {blueLine}

            {/* STEP 8 */}
            {
                validateShowInput('IP') &&
                <>
                    <Box display={'flex'} >
                        <Box width={'100%'} >
                            <Box className={classes.devicesLabel}>
                                IP
                            </Box>
                            <Box >
                                <Box className={classes.input} >
                                    <TextField
                                        disabled={disableEditing}
                                        fullWidth
                                        type={"text"}
                                        name='ip'
                                        // label={'Agregue un valor'}
                                        size='small'
                                        variant='outlined'
                                        value={stateForm?.ip ? stateForm?.ip : ''}
                                        onChange={handleChange}
                                        color='primary'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {blueLine}
                </>
            }


            {/* STEP 9 */}
            {
                validateShowInput('Puerto') &&
                <>
                    <Box display={'flex'} >
                        <Box width={'100%'} >
                            <Box className={classes.devicesLabel}>
                                Puerto
                            </Box>
                            <Box >
                                <Box className={classes.input} >
                                    <TextField
                                        disabled={disableEditing}
                                        fullWidth
                                        type={"number"}
                                        name='p'
                                        // label={'Agregue un valor'}
                                        size='small'
                                        variant='outlined'
                                        value={stateForm?.p ? stateForm?.p : ''}
                                        onChange={handleChange}
                                        color='primary'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {blueLine}
                </>
            }
            {/* STEP 10 */}
            {
                validateShowInput('ID') &&
                <>
                    <Box display={'flex'} >
                        <Box width={'100%'} >
                            <Box className={classes.devicesLabel}>
                                ID
                            </Box>
                            <Box >
                                <Box className={classes.input} >
                                    <TextField
                                        disabled={disableEditing}
                                        fullWidth
                                        // type={"number"}
                                        name='id'
                                        // label={'Agregue un valor'}
                                        size='small'
                                        variant='outlined'
                                        value={stateForm?.id ? stateForm?.id : ''}
                                        onChange={handleChange}
                                        color='primary'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
        </Box >
    )
}
export default MbrFilterOptions





