import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { blueBar } from '../../helpers/common';
import { debounce } from 'lodash';
import SelectNodeLabel from '../InstantAdvanced/SelectNodeLabel';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { getExtraInfo } from '../../services/hierarchy';
import TimeSlotSelector from '../common/TimeSlotSelector';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-root': {
      padding: theme.spacing(1),
    },
    steppersFilter: {
      '&.MuiStep-horizontal': {
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
  },
  label: {
    marginLeft: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(1),
      marginBottom: '5px',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5),
  }
}));

export default function DatasetFilter({
  setShowDatasetFilter,
  datasetSelected,
  setMainObj,
  mainObj,
  indexGroupselected,
  indexDataSetSelected,
  setActiveStepMainFilter,
}) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect(990);
  const steps = [
    'Elija un dispositivo',
    'Seleccione un alias',
    'Seleccione un color',
    'Seleccione una franja horaria (Opcional)',
  ];
  const blueLine = blueBar();
  const [dataset, setDataset] = useState(datasetSelected);
  const [disabledTimeSlot, setDisabledTimeSlot] = useState(datasetSelected?.timeslots ? false : true);
  const [showDevices, setShowDevices] = useState(false);
  const [nodeLabelSelected, setNodeLabelSelected] = useState({});
  const [activeStep, setActivestep] = useState(0);
  const [workHours, setWorkHours] = useState([]);
  const [nonWorkHours, setNonWorkHours] = useState([]);
  
  useEffect(() => {
    calculateActiveStep();
    if (dataset?.devices?.[0]?.id) {
      getExtraInfoNode(dataset?.devices?.[0]?.id);
    }
  }, []);

  useEffect(() => {
    if (nodeLabelSelected?.selectedNodes) {
      let devices = nodeLabelSelected?.selectedNodes;
      if (nodeLabelSelected?.selectedNodes.length === 1) {
        let nodeId = nodeLabelSelected?.selectedNodes[0].id;
        getExtraInfoNode(nodeId);
      } else {
        setActivestep(0);
      }
      if (nodeLabelSelected.selectedLabel) {
        let { id, nombre } = nodeLabelSelected.selectedLabel;
        devices[0].label_id = id;
        devices[0].label_nombre = nombre;
      }
      if (!dataset.devices) {
        setActivestep(3);
      }
      setDataset(prevDataset => ({
        ...prevDataset,
        devices,
        color: devices?.length === 1 ? devices[0]?.color : '',
        alias: devices?.length === 1 ? devices[0]?.nombre : '',
      }));
    }
  }, [nodeLabelSelected]);

  const getExtraInfoNode = async nodeId => {
    let extraInfo = await getExtraInfo(nodeId);
    if (extraInfo?.data?.workhours?.length > 0) {
      setWorkHours(extraInfo?.data?.workhours);
      setNonWorkHours(extraInfo?.data?.non_workhours);
    }
  };

  const handleSubmit = () => {
    if (dataset?.devices?.length > 1) {
      handleMultiplesDevices();
    } else {
      mainObj.groups[indexGroupselected].datasets[indexDataSetSelected] =
        dataset;
      setMainObj(mainObj);
    }
    
    setShowDatasetFilter(false);
    if (indexGroupselected === 0) {
      setActiveStepMainFilter(3);
    } else if (indexGroupselected === 1) {
      setActiveStepMainFilter(4);
    } else if (indexGroupselected === 2) {
      setActiveStepMainFilter(5);
    }
  };

  const handleMultiplesDevices = () => {
    let devices = [...dataset.devices];
    let datasets = devices.map(device => ({
      devices: [device],
      alias: device.nombre,
      color: device.color,
      timeslots: dataset.timeslots,
    }));
    mainObj.groups[indexGroupselected].datasets.push(...datasets);
  };

  const handleAlias = e => {
    setDataset({
      ...dataset,
      alias: e?.target?.value,
    });
    if (!dataset.alias) {
      setActivestep(1);
    }
  };

  const handleColorChange = debounce(value => {
    setDataset({
      ...dataset,
      color: value,
    });
    setActivestep(3);
  });

  const handleTimeSlot = timeslots => {
    setDataset(prevDataset => ({
      ...prevDataset,
      timeslots: disabledTimeSlot ? '' : timeslots,
    }));
  };

  const handleOpenCloseSelectNodeTag = () => {
    setShowDevices(!showDevices);
  };

  const handleCancel = () => {
    setShowDatasetFilter(false);
  };

  const handleDeleteDevice = index => {
    let devices = [...dataset.devices];
    devices.splice(index, 1);
    setDataset({
      ...dataset,
      devices: devices,
    });
  };

  function calculateActiveStep() {
    if (datasetSelected?.timeslots) {
      setActivestep(4);
    } else if (dataset?.color) {
      setActivestep(3);
    } else {
      setActivestep(0);
    }
  }

  return (
    <>
      <Stepper
        className={classes.steppersFilter}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{isMobile ? '' : label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {blueLine}

      <form>
        <Grid container style={{ padding: 8 }}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={2}>
              <Tooltip
                title="Elija el/los dispositivos que desea añadir al gráfico haciendo click en el botón +"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 0 },
                    { [classes.tick]: dataset?.devices }
                  )}
                >
                  1
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel}>Agregar dispositivo</Box>
              <IconButton
                className={classes.addBtn}
                onClick={() => {
                  setShowDevices(true);
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>

              {dataset?.devices?.map((node, index) => (
                <Chip
                  key={node.id}
                  icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                  label={`${node.nombre}
                          ${
                            node.label_nombre ? ` + ${node.label_nombre}` : ''
                          }`}
                  clickable
                  color="primary"
                  style={{ marginLeft: '5px' }}
                  onClick={() => handleOpenCloseSelectNodeTag('edit')}
                  onDelete={() => handleDeleteDevice(index)}
                />
              ))}
              {(!dataset.devices || dataset?.devices?.length === 0) && (
                <Box
                  ml={1}
                  mt={1}
                  className={classes.devicesLabel}
                  width={'100%'}
                >
                  Debe seleccionar al menos un dispositivo.
                </Box>
              )}
              <Box
                ml={1}
                mt={1}
                className={classes.devicesLabel}
                width={'100%'}
              >
                Nota: Si selecciona múltiples dispositivos se creará un dataset
                para cada uno.
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={3}>
              <Tooltip title="Alias" arrow placement="right">
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 1 },
                    { [classes.tick]: dataset?.alias }
                  )}
                >
                  2
                </Box>
              </Tooltip>
              <TextField
                autoFocus
                placeholder="Alias"
                name="name"
                onChange={e => {
                  handleAlias(e);
                }}
                value={dataset?.alias || ''}
                color="primary"
                disabled={
                  dataset?.devices?.length > 1 ||
                  !dataset.devices ||
                  dataset.devices.length === 0
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={3}>
              <Tooltip title="Color" arrow placement="right">
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 2 },
                    { [classes.tick]: activeStep > 2 || dataset?.color }
                  )}
                >
                  3
                </Box>
              </Tooltip>
              <Box className={classes.devicesLabel}>Seleccione un color</Box>
              <TextField
                style={{ width: '40px', marginLeft: '15px' }}
                type="color"
                value={dataset?.color || ''}
                name="color1"
                onChange={e => handleColorChange(e.target.value)}
                disabled={
                  dataset?.devices?.length > 1 ||
                  !dataset.devices ||
                  dataset.devices.length === 0
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} mt={1}>
            <Box display="flex" alignItems="start" flexWrap="wrap" mt={2}>
              <Tooltip title="Franja horaria" arrow placement="right">
                <Box
                  marginTop={1}
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    { [classes.active]: activeStep === 3 },
                    { [classes.tick]: dataset.timeslots }
                  )}
                >
                  4
                </Box>
              </Tooltip>
              <Box display={'flex'} alignItems={'center'}>
                <Box className={classes.devicesLabel} mr={1}>
                  Franja horaria
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      name="showSecondary"
                      checked={!disabledTimeSlot}
                      onChange={() => setDisabledTimeSlot(!disabledTimeSlot)}
                      color="primary"
                      disabled={
                        !dataset.devices || dataset.devices.length === 0
                      }
                    />
                  }
                />
              </Box>
            </Box>

            <Box ml={2}>
              <Box mt={1} display={'flex'}>
                <TimeSlotSelector
                  timeSlots={dataset.timeslots}
                  disabledTimeSlot={disabledTimeSlot}
                  handleSetTimeSlots={handleTimeSlot}
                  workHours={workHours}
                  nonWorkHours={nonWorkHours}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {blueLine}

        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
            disabled={
              (dataset?.devices?.length === 1 &&
                (!dataset?.devices || !dataset?.alias)) ||
              !dataset?.devices ||
              dataset?.devices.length === 0
            }
          >
            Aceptar
          </Button>

          <Button
            variant="contained"
            onClick={handleCancel}
            className={classes.btn}
          >
            Cancelar
          </Button>
        </Box>
      </form>
      {showDevices && (
        <SelectNodeLabel
          showDevices={showDevices}
          setNodeLabelSelected={setNodeLabelSelected}
          handleOpenCloseSelectNodeTag={handleOpenCloseSelectNodeTag}
          multi={true}
        />
      )}
    </>
  );
}
