/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import {
    Box, Chip, FormControl, IconButton,
    InputLabel, Select, TextField, MenuItem,
    Step, StepLabel, Stepper,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import clsx from 'clsx';
import DynamicSelect from '../common/DynamicSelect';
import SelectNodeTag from './SelectNodeLabel';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { blueBar } from '../../helpers/common'
import UserContext from '../../context/UserContext';
import { debounce } from 'lodash';
import TimeSlotSelector from '../common/TimeSlotSelector';
import { getExtraInfo } from '../../services/hierarchy';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        ...scrollsSettings,
        height: '60%',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
    },
    steppersFilter: {
        '&.MuiStep-horizontal': {
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    addBtn: {
        margin: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    filterChip: {
        margin: theme.spacing(0.5),
        '@media (max-width: 700px)': {
            width: '250px',
        },
    },
    btn: {
        margin: theme.spacing(3, 1),
        '@media (max-width: 700px)': {
            fontSize: '13px',
            padding: '1%',
        },
    },
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3),
        width: theme.spacing(3),
        minWidth: '25px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 16,
        color: theme.palette.primary.dark,
    }
}));

export default function DatasetFilter({ initialDataset, indexGroupSelected, indexDataSetSelected, handleDataSetChanges, setOpenDataSetFilter, varsCatalog, statusNodes }) {
    const classes = useStyles();
    const blueLine = blueBar()
    const { plan } = useContext(UserContext);
    const { isMobile } = useDeviceDetect(990);
    const [activeStep, setActiveStep] = useState(0);
    const [showDevices, setShowDevices] = useState(false);
    const [nodeLabelSelected, setNodeLabelSelected] = useState({});
    const steps = [
        'Seleccione uno o más dispositivos',
        'Seleccione variable a graficar',
        'Seleccione un color',
        'Elija un alias',
    ];
    const [dataSet, setDataSet] = useState(initialDataset)
    const [disabledTimeSlot, setDisabledTimeSlot] = useState(true);
    const enabledItems = plan?.sections['INSTANTANEOS']?.filter(
        item => item.enabled
    );
    const [workHours, setWorkHours] = useState([])
    const [nonWorkHours, setNonWorkHours] = useState([])
    //Deshabilita el input de granularidad si no existe.
    const [disabledInputGranularity, setDisabledInputGranularity] = useState(false)
    const objectCatalog = {};
    const [initialGroupListVariables, setinitialGroupListVariables] = useState([])
    const [groupListVariables, setGroupListVariables] = useState([])

    useEffect(() => {
        const groupList = getCatalogo('groups_vars', 'INSTANTANEOS');
        const varsList = changingCatalogProp('INSTANTANEOS')
        const updatedGroupList = groupList.map(group => {
            const children = varsList.filter(variable => variable.group.key === group.key);
            return {
                ...group,
                children: group.children ? [...group.children, ...children] : children
            };
        });
        setinitialGroupListVariables(updatedGroupList)
        setGroupListVariables(updatedGroupList);
    }, [])

    enabledItems.forEach(item => {
        objectCatalog[item.key] = {
            key: item.key,
            name: item.tag ? item.tag : item.name,
            fields: item.fields,
            periods: item.periods,
            phase: item.phase,
        };
    });

    //Coloca el step como realizado si no tiene granularidad
    useEffect(() => {
        if (disabledInputGranularity) {
            setActiveStep(5);
        }
    }, [disabledInputGranularity])

    useEffect(() => {
        if (dataSet.timeslots) {
            setDisabledTimeSlot(false)
        }
        if (dataSet?.devices?.[0]?.id) {
            getExtraInfoNode(dataSet?.devices?.[0]?.id);
        }
        calculateActiveStep();
    }, [])

    useEffect(() => {
        //Selecciona nodo.
        if (nodeLabelSelected?.selectedNodes) {
            let devices = nodeLabelSelected?.selectedNodes
            if (nodeLabelSelected?.selectedNodes.length === 1) {
                let nodeId = nodeLabelSelected?.selectedNodes[0].id
                getExtraInfoNode(nodeId);
                setActiveStep(1)
            }
            else {
                setActiveStep(0)
            }
            if (nodeLabelSelected.selectedLabel) {
                let { id, nombre } = nodeLabelSelected.selectedLabel
                devices[0].label_id = id
                devices[0].label_nombre = nombre
            }
            setDataSet(prevDataset => ({
                ...prevDataset,
                variable: '',
                devices,
                color: devices?.length === 1 ? devices[0]?.color : '',
                alias: devices?.length === 1 ? devices[0]?.nombre : ''
            }));
        }
        filterListVariable(nodeLabelSelected?.selectedNodes)
    }, [nodeLabelSelected]);

    function calculateActiveStep() {
        if (dataSet?.timeslots) {
            setActiveStep(6);
        } else if (dataSet?.granularity) {
            setActiveStep(5);
        } else if (dataSet?.variable) {
            setActiveStep(4);
        }
    }

    // Obtiene el catalogo
    const getCatalogo = (option, section) => {
        let nameVarLocal = 'UI_ROLES'
        let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal))?.[option][section]
        return VARS_CATALOGO
    };
    //Esta funcion modifica la  busqueda de la lista de variables en ciertos casos por q la estructura cambia en ciertas secciones.
    const changingCatalogProp = (section) => {
        let res = getCatalogo('sections', section);
        if (section === 'ALERTS_VARIABLES') {
            res = res.detail_data
        }
        return res
    }

    //Filtra las variables personalizadas q tengan una relacion con los nodos seleccionados
    const filterListVariable = (nodesSeleced) => {
        if (groupListVariables.length > 0) {
            let excludedKeys = new Set(['ENERGIA_ELECTRICA', 'ENERGIAS_RENOVABLES', 'VARIABLES_METEOROLOGICAS']);
            let filterGenericVars = initialGroupListVariables.filter(item => excludedKeys.has(item.key));
            let filterCustomVars = []
            nodesSeleced?.forEach(node => {
                if (node?.automates?.length > 0) {
                    node?.automates?.forEach(automate => {
                        filterCustomVars = initialGroupListVariables?.filter(item => !excludedKeys?.has(item.key));
                        let filterElementVar = filterCustomVars?.filter(item => item.key === automate?.llave)
                        filterCustomVars = filterElementVar
                    })
                    setDataSet(prevDataset => ({
                        ...prevDataset,
                        variable: ''
                    }));
                }
            })
            let arryAux = [...filterGenericVars, ...filterCustomVars]
            setGroupListVariables(arryAux)
        }
    }

    const getExtraInfoNode = async (nodeId) => {
        let extraInfo = await getExtraInfo(nodeId);
        if (extraInfo?.data?.workhours?.length > 0) {
            setWorkHours(extraInfo?.data?.workhours)
            setNonWorkHours(extraInfo?.data?.non_workhours)
        }
    }

    const handleOpenCloseSelectNodeTag = () => {
        setShowDevices(!showDevices)
    }

    const handleFilterChange = e => {
        const { name, value } = e.target;
        setDataSet({
            ...dataSet,
            [name]: value,
        });
        if (name === 'variable') {
            setActiveStep(4);
            let variableAux = varsCatalog?.find(item => item.key === value)
            let period = ''
            if (variableAux?.periods?.length > 0) {
                period = variableAux?.periods[0]?.key
            }
            if (dataSet.granularity === '') {
            }
            //Si elije la otra variable y la granularidad q tenia coincide con una opcion valida la sigo mostrando.
            let findSameGranularity = variableAux?.periods?.find(item => item.key === dataSet?.granularity)
            return setDataSet({
                ...dataSet,
                variable: value,
                base_unit: variableAux?.base_unit,
                granularity: findSameGranularity ? findSameGranularity?.key : period
            });
        }
    };

    const handleSubmit = () => {
        handleDataSetChanges(dataSet, indexDataSetSelected, indexGroupSelected)
        setOpenDataSetFilter(false)
    }

    const handleColorChange = debounce(value => {
        setDataSet({
            ...dataSet,
            color: value,
        });
    });

    const handleDeleteDevice = (index) => {
        let devices = [...dataSet.devices]
        devices.splice(index, 1)
        setDataSet({
            ...dataSet,
            devices: devices,
        });
    }

    const handleSetTimeSlots = (timeslots) => {
        setDataSet(prevDataset => ({
            ...prevDataset,
            timeslots: timeslots
        }));
    }


    return (
        <>
            <SelectNodeTag
                showDevices={showDevices}
                statusNodes={statusNodes}
                setNodeLabelSelected={setNodeLabelSelected}
                handleOpenCloseSelectNodeTag={handleOpenCloseSelectNodeTag}
                initialSelectedNodes={dataSet?.devices}
                multi={true}
            />

            <Box mt={3}>
                <Stepper
                    className={classes.steppersFilter}
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {steps.map(label => (
                        <Step className={classes.steppersFilter} key={label}>
                            <StepLabel>{isMobile ? '' : label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>


            {blueLine}

            {/* CONTAINER */}
            <Box p={3} className={classes.root}>
                <Box >
                    {/* STEP 1 */}
                    <Box
                        m={2}
                        display="flex" alignItems="center" flexWrap="wrap" gridGap={1}
                    >
                        <Tooltip
                            title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0 && dataSet?.devices?.length === 0, },
                                { [classes.tick]: dataSet?.devices?.length > 0 })}>
                                1
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>
                            Agregar dispositivo
                        </Box>
                        <IconButton
                            aria-label="Agregar dataset"
                            className={classes.addBtn}
                            onClick={() => handleOpenCloseSelectNodeTag()}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                        {dataSet?.devices?.map((node, index) =>
                        (
                            <Chip
                                key={node.id}
                                icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                                label={`${node.nombre}${node.label_nombre ? ` + ${node.label_nombre}` : ''}`}
                                clickable
                                color="primary"
                                onClick={() => handleOpenCloseSelectNodeTag('edit')}
                                onDelete={() => handleDeleteDevice(index)}
                                className={classes.filterChip}
                            />
                        )
                        )}
                        {(!dataSet.devices || dataSet?.devices?.length === 0) && <Box ml={3} mt={1} className={classes.devicesLabel} width={'100%'}>Debe seleccionar al menos un dispositivo.</Box>}
                        <Box ml={3} mt={1} className={classes.devicesLabel} width={'100%'}>Nota: Si selecciona múltiples dispositivos se creará un dataset para cada uno.</Box>
                    </Box>

                    {blueLine}

                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title="Elija una variable"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 3, }, { [classes.tick]: dataSet?.variable, })}>
                                2
                            </Box>
                        </Tooltip>
                        <Box mt={1} mb={2} width={'80%'}>
                            <DynamicSelect
                                groupListVariables={groupListVariables}
                                handleChangeProp={handleFilterChange}
                                valueSelect={dataSet?.variable ? dataSet?.variable : ''}
                                keyName={'variable'}
                                InputLabelProp={'Variable a graficar'}
                                section={'INSTANTANEOS'}
                                placeHolder={'Variable'}
                                setDisabledInputGranularity={setDisabledInputGranularity}
                            />
                        </Box>
                    </Box>

                    {blueLine}

                    <Box display="flex" alignItems="center" flexWrap="wrap" m={2}>
                        <Tooltip title="Color" arrow placement="right">
                            <Box
                                className={clsx(
                                    classes.hint,
                                    classes.leftSpacing,
                                    { [classes.active]: activeStep === 2 },
                                    { [classes.tick]: dataSet?.color }
                                )}
                            >
                                3
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>Seleccione un color</Box>
                        <TextField
                            style={{ width: '40px', marginLeft: '15px' }}
                            type="color"
                            value={dataSet?.color || ''}
                            name="color1"
                            onChange={e => handleColorChange(e.target.value)}
                        // disabled={dataSet?.devices?.length > 1}
                        />
                    </Box>

                    {blueLine}

                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title='Ingrese un alias'
                            arrow
                            placement='right'
                        >
                            <Box
                                className={clsx(
                                    classes.hint,
                                    classes.leftSpacing,
                                    { [classes.active]: activeStep === 3 },
                                    { [classes.tick]: dataSet?.alias }
                                )}
                            >
                                4
                            </Box>
                        </Tooltip>

                        <Box className={classes.devicesLabel}>
                            <Box className={classes.devicesLabel}>
                                Alias
                            </Box>
                            <TextField
                                className={classes.input}
                                type='text'
                                name='alias'
                                value={dataSet?.alias}
                                onChange={handleFilterChange}
                                // disabled={dataSet.devices?.length > 1}
                                color='primary'
                            />
                        </Box>
                    </Box>

                    {!dataSet.variable && <Box ml={4} mt={1} className={classes.devicesLabel} width={'100%'}>Debe seleccionar una variable.</Box>}
                </Box>

                {blueLine}

                {/* Esconder granularidad
                <Box >
                    <Box
                        m={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Tooltip
                            title="Elija la granularidad"
                            arrow
                            placement="right"
                        >
                            <Box className={clsx(classes.hint, classes.leftSpacing, {
                                [classes.active]: activeStep === 4,
                            }, { [classes.tick]: dataSet?.granularity })}
                            >
                                5
                            </Box>
                        </Tooltip>
                        <FormControl style={{ width: '72%' }}>
                            <InputLabel id="granularityLabel" shrink>Granularidad</InputLabel>
                            <Select
                                disabled={disabledInputGranularity}
                                labelId="granularityLabel"
                                name="granularity"
                                value={dataSet?.granularity ? dataSet?.granularity : ''}
                                onChange={handleFilterChange}
                            >
                                {objectCatalog[dataSet?.variable]?.periods?.map(option => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {!dataSet.granularity && <Box ml={4} mt={1} className={classes.devicesLabel} width={'100%'}>Debe seleccionar una granularidad.</Box>}
                </Box> */}

                {/* {blueLine}

                <Box>
                    <Box m={2} display="flex" alignItems="center">
                        <Tooltip
                            title="Configure una franja horaria"
                            arrow
                            placement="right">
                            <Box className={clsx(classes.hint, classes.leftSpacing, {
                                [classes.active]: activeStep === 5,
                            }, { [classes.tick]: dataSet.timeslots?.length > 0 })}
                            >
                                6
                            </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel} mr={1}>Franja horaria</Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!disabledTimeSlot}
                                    onChange={() => setDisabledTimeSlot(!disabledTimeSlot)}
                                    color="primary"
                                />
                            }
                        />
                    </Box>
                    <Box m={2} ml={4}>
                        <TimeSlotSelector
                            timeSlots={dataSet.timeslots}
                            disabledTimeSlot={disabledTimeSlot}
                            handleSetTimeSlots={handleSetTimeSlots}
                            workHours={workHours}
                            nonWorkHours={nonWorkHours}
                        />
                    </Box>
                </Box> */}
            </Box>
            {/* Botones */}
            <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                    className={classes.btn}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={dataSet.devices?.length === 0 || !dataSet.variable}
                >
                    Guardar
                </Button>

                <Button
                    className={classes.btn}
                    variant="contained"
                    onClick={() => setOpenDataSetFilter(false)}
                >
                    Cancelar
                </Button>
            </Box>
        </>
    );
}
