import { makeStyles, Toolbar, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	buttonChange: {
		paddingBottom: '5px',
		paddingTop: '5px',
		backgroundColor: theme.palette.primary.main,
		//estilo agregado para q en la funcion windows.print() este componente nunca este en la imagen de el pdf.
		"@media print": {
			display: 'none'
		},
		"@media (max-width: 920px)": {
			display: 'flex',
			flexDirection: 'column'
		}
	},
	toolbar: {
		paddingBottom: '5px',
		paddingTop: '5px',
		backgroundColor: theme.palette.primary.main,
		"@media (max-width: 920px)": {
			display: "flex",
			flexWrap: "wrap"
		},
		//estilo agregado para q en la funcion windows.print() este componente nunca este en la imagen de el pdf.
		"@media print": {
			display: 'none'
		},
	},
	titulo: {
		marginLeft: 64,
		color: theme.palette.common.white,
		cursor: "default",
		"@media (max-width: 920px)": {
			marginLeft: 5,
			marginTop: 10
		}
	},
	containerChildren: {
		position: 'relative',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
	}
}));

export default function TopNav(props) {
	const classes = useStyles();
	const marginTop = () => {
		if (props.marginTop === true) {
			return 0
		} else {
			return 48
		}
	}

	const marginLeft = () => {
		if (props.marginLeft === true) {
			return 0
		}
	}
	return (
		<Toolbar className={props?.changeTitleStyleFlex ? classes.buttonChange : classes.toolbar} style={{ marginTop: marginTop() }}>

			{props.iconButton}
			<Typography variant='subtitle1' className={classes.titulo} style={{ marginLeft: marginLeft() }}>
				{props.titulo} <span style={{ fontWeight: '300' }}>{props.subtitulo}</span>
			</Typography>

			<Box className={classes.containerChildren}>
				{props.children}
			</Box>

		</Toolbar>
	);
}
