import { useEffect, useRef } from 'react';
import {
    getStateAutomate
} from "../../../services/plc_automate.js";

const useChangeIconStateAutomate = (componentType, params_id, setStateNavIcon) => {
    const intervalRef = useRef(null);
    useEffect(() => {
        if (componentType === 'Automate-24V-4M') {
            if (!params_id) return;
            let flag = 1;
            setStateNavIcon({
                key: "IN_PROCESS",
                value: "Consulta en Proceso"
            });
            const fetchState = async () => {
                try {
                    let res = await getStateAutomate(params_id);
                    let valueAux = {
                        key: res?.data?.virtual_state,
                        value: res?.data?.virtual_state_value
                    };
                    if (res?.data?.virtual_state === "IN_PROCESS") {

                    }
                    else {
                        flag = 8
                    }
                    setStateNavIcon(valueAux);
                    flag += 1;
                }
                catch (error) {
                    console.error("Error en la request:", error);
                    clearInterval(intervalRef.current);
                    setStateNavIcon({
                        key: "ERROR_COMUNICATION",
                        value: "Error en la comunicación"
                    });
                }
            };
            intervalRef.current = setInterval(() => {
                if (flag >= 9) {
                    clearInterval(intervalRef.current);
                    return;
                }
                fetchState();
            }, 4000);
            // Cleanup: Se ejecuta cuando el componente se desmonta o cambia params_id
            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        }
        // eslint-disable-next-line
    }, [params_id, setStateNavIcon]); // Dependencias para evitar recreación innecesaria
};

export default useChangeIconStateAutomate;