import React from 'react';
//Material ui.
import {
    makeStyles
} from '@material-ui/core';
import { Card, CardContent, Typography, Button, ListItem, Divider, Box } from '@mui/material';
import { benefits } from './helpers';
const scrollsSettings = {
    overflow: 'scroll',
    height: '50vh',
    overflowX: 'hidden',
};

const webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    width: '9px',
};

const webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    listModules: {
        border: '1px solid #b5b5b578',
        padding: '5px',
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '@media (max-width: 960px)': {
            width: '100%',
        },
    },
    planCard: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        maxWidth: '300px',
        fontFamily: 'Arial, sans-serif',
    },
    planHeader: {
        backgroundColor: '#f5f5f5',
        padding: '8px',
        borderRadius: '8px 8px 0 0',
        textAlign: 'center',
    },
    planFeatures: {
        listStyle: 'none',
        padding: 0,
        margin: '16px 0',
    },
    planFeatureItem: {
        marginBottom: '8px',
    },
    planPrice: {
        textAlign: 'center',
    },
    planButton: {
        display: 'block',
        margin: '16px auto',
        padding: '10px 20px',
        backgroundColor: '#e50914',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#b00610',
        },
    },
    planDetails: {
        fontSize: '14px',
        marginTop: '16px',
    },
    containerCard: {
        width: '500px',
        maxWidth: '500px',
        position: 'relative',
        "@media (max-width: 1400px)": {
            width: '31%',
        },
        "@media (max-width: 940px)": {
            width: '46%',
        },
        "@media (max-width: 540px)": {
            width: '100%',
        },
    },
    blueBarTop: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    mainContent:{
        minHeight:'200px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
    }
}))

export default function CardComponent({ userInfo, plan, handleOpenCloseModalPay }) {
    const classes = useStyles()

    const planBenefits = benefits.find(benefit => benefit.plan === plan?.name)    

    const actualPlan = () => {
        if (userInfo?.info_user?.plan_actual === plan?.name) {
            return true
        }
        else {
            return false
        }
    }

    const getAnnualPrice = (monthlyPrice) => {
        let anualPrice = (monthlyPrice * 11) / 12
        return anualPrice.toFixed(2)
    }

    return (
        <Card key={plan?.id} className={classes.containerCard} sx={{ margin: '10px', border: actualPlan() ? '1px solid #78b0ff' : '1px solid #ccc', borderRadius: '8px', }}>

            {/* Linea superior gradient */}
            <Box className={classes.blueBarTop} style={{ height: actualPlan() ? '' : '7px', background: 'linear-gradient(45deg, #1E3A8A, #3B82F6, #60A5FA)' }}>
                <Box ml={1} fontSize={12} color={'white'} p={0.5} fontWeight={'bold'}>
                    {actualPlan() ? 'Plan actual' : ''}
                </Box>
            </Box>

            {/* Titulo */}
            <Box mt={1} backgroundColor='#f5f5f582' p={3} >
                <Box fontSize={15} fontWeight='bold' color='#5b6065'>
                    Plan
                </Box>
                <Box fontSize={19} fontWeight='bold' color='#4f4f4f' >
                    {plan?.name}
                </Box>

            </Box>

            {/* Contenido inicial */}
            <CardContent className={classes.mainContent}>
                <Box >
                    {planBenefits?.headers.map((elem) => {
                        return (
                            <Box key={elem} maxWidth={'320px'}>
                                <Box ml={1} >
                                    <ListItem sx={{ fontSize: '13px', padding: 0, marginBottom: '8px', color: '#808080', fontWeight: 'bold' }}>{elem}</ListItem>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                {/* <Divider /> */}
                <Box>
                    <Typography variant="h5">{`$ ${plan?.costo_mensual}/mes`}</Typography>
                    <Typography color='#808080' fontSize='15px' variant="body2">{`$ ${getAnnualPrice(plan?.costo_mensual)}/mes`} si elige pagar por un año</Typography>
                </Box>
            </CardContent>

            {/* Botones */}
            <Box pr={2} pl={2}  >
                <Button
                    onClick={() => handleOpenCloseModalPay(plan)}
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                    {actualPlan() ? 'Modificar Plan' : 'Elegir plan'}
                </Button>
            </Box>

            {/* Linea inferior division */}
            <Box pr={2} pl={2} pt={2} >
                <Divider />
            </Box>

            {/* Que incluye */}
            <CardContent>
                <Typography color='#656363' fontSize={14} variant="subtitle1" gutterBottom><strong>QUÉ INCLUYE</strong></Typography>
                <Box className={classes.listModules} overflow={'auto'} maxHeight={'200px'} style={{ overflowX: 'hidden' }}>
                    {planBenefits?.modules.map((module, index) => (
                        <Typography key={index} color='#656363' variant="body2">
                            <strong dangerouslySetInnerHTML={{ __html: module }}></strong>
                        </Typography>
                    ))}
                </Box>
            </CardContent>
        </Card >
    )
}

