import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import {
  Box, Chip, Dialog, IconButton,
  Step, StepLabel, Stepper,
} from '@material-ui/core';
import clsx from 'clsx';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { blueBar } from '../../helpers/common'
import DatasetFilter from './DatasetFilter';
import { DATASET_INITIAL_VALUES, getChipLabel } from './helpers';
import DatePickerCommon from '../DatePicker/DatePickerCommon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//Helpers
import { getStatusNodes } from '../../services/meters';
import cloneDeep from 'lodash/cloneDeep';

let scrollsSettings = {
  overflow: 'scroll',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    ...scrollsSettings,
    height: '70%',
    '@media (max-width: 920px)': {
      height: '70%',
    },
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  steppersFilter: {
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
    '@media (max-width: 700px)': {
      fontSize: '13px',
      padding: '1%',
    },
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  wrapperDatepicker: {
    position: 'relative',
    width: '100%',
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
    marginTop: '20px',
  },
  title: {
    padding: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.primary.dark,
  },
  containerGrapSettings: {
    minHeight: '34%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid #00000029', // Borde sólido negro de 1px
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  dialogStyle: {
    '& .MuiDialog-paperScrollPaper': {
      // height: '100%'
    },
    '& .MuiDialogContent-root': {
      '@media (max-width: 900px)': {
        padding: '2px'
      },
    },
    '& .MuiDialog-paper': {
      '@media (max-width: 600px)': {
        margin: '0px'
      },
    },
    '& .MuiDialog-paperFullWidth': {
      '@media (max-width: 600px)': {
        width: '100%'
      },
    },
  }
}));

export default function Filter({ handleClose, initialFilter, onSubmit, varsCatalog }) {
  const classes = useStyles();
  const blueLine = blueBar()
  const { isMobile } = useDeviceDetect(990);
  const [activeStep, setActiveStep] = useState(0);

  ///CONTROLAR!!!
  const [filter, setFilter] = useState(cloneDeep(initialFilter))
  const [openDataSetFilter, setOpenDataSetFilter] = useState(false);
  const [indexDataSetSelected, setIndexDataSetSelected] = useState(null)
  const [indexGroupSelected, setIndexGroupSelected] = useState(null)
  const steps = [
    'Configure gráfico principal',
    'Seleccione el rango de fechas',
    'Haga click en CONSULTAR',
  ];
  const datasetInitialValues = DATASET_INITIAL_VALUES
  const [date, setDate] = useState(null)
  const [statusNodes, setStatusNodes] = useState([]);

  //Verifica status de el medidor.
  useEffect(() => {
    const getStatusNodesAux = async () => {
      let res = await getStatusNodes()
      let arry = res.data
      setStatusNodes(arry)
    }
    getStatusNodesAux()
  }, [])


  const handleOpenDataSet = (groupIndex, datasetIndex) => {
    setIndexGroupSelected(groupIndex)
    setIndexDataSetSelected(datasetIndex)
    setOpenDataSetFilter(!openDataSetFilter)
  }

  //DESARROLLO
  const handleDeleteDataSet = (groupIndex, datasetIndex) => {
    let filterAux = { ...filter }
    console.log(filterAux)
    filterAux?.groups[groupIndex]?.dataSets?.splice(datasetIndex, 1)
    console.log(filterAux)
    // let auxArry = [...filterAux]
    // auxArry?.splice(1, 1)
    setFilter(filterAux)
  }

  const handleSubmit = () => {
    onSubmit(filter)
  }

  const handleDataSetChanges = (dataset, indexDataSet, indexGroup) => {
    if (dataset?.devices?.length > 1) {
      handleMultiplesDevices(dataset, indexGroup);
    }
    else {
      let filterAux = { ...filter }
      let existentDataset = filterAux.groups[indexGroup].dataSets[indexDataSet]
      if (existentDataset) {
        filterAux.groups[indexGroup].dataSets[indexDataSet] = dataset
      }
      else {
        filterAux.groups[indexGroup].dataSets.push(dataset)
      }
      setFilter(filterAux)
    }
  }

  const handleMultiplesDevices = (dataset, indexGroup) => {
    let devices = [...dataset.devices]
    let filterAux = { ...filter }
    let datasets = devices.map((device) => ({
      devices: [device],
      alias: device.nombre,
      color: device.color,
      timeslots: dataset.timeslots,
      variable: dataset.variable,
      granularity: dataset.granularity
    }))
    filterAux.groups[indexGroup].dataSets.push(...datasets)
    setFilter(filterAux)
  }

  const handleDateChange = (dateStart, dateEnd, indexGroup) => {
    let filterAux = { ...filter }
    filterAux.groups[indexGroup].selectedInitialDate = dateStart
    filterAux.groups[indexGroup].selectedEndDate = dateEnd
    setFilter(filterAux)
  }

  const handleCopyDataset = () => {
    let filterAux = { ...filter }
    filterAux.groups[1].dataSets = [...filterAux.groups[0].dataSets]
    setFilter(filterAux)
  }


  const addSecondaryGraph = () => {
    setFilter({
      ...filter,
      groups: [...filter.groups,
      {
        title: 'Grafico Secundario',
        disabled: true,
        dataSets: [],
        selectedInitialDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
        selectedEndDate: new Date(),
      },
      ]
    })
  }

  const deleteSecondaryGraph = () => {
    let filterAux = { ...filter }
    filterAux?.groups?.splice(1, 1)
    setFilter(filterAux)
  }

  return (
    <>
      <Box pt={2} >
        <Stepper
          className={classes.steppersFilter}
          activeStep={activeStep}
          alternativeLabel

        >
          {steps.map(label => (
            <Step className={classes.steppersFilter} key={label}>
              <StepLabel>{isMobile ? '' : label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box p={3} className={classes.root}>
        {blueLine}
        <Box mt={1} mb={2}>
          <p>Bienvenido a Instantáneos avanzado, en esta sección usted podrá construir gráficos de línea versátiles que se adapten al análisis que desee realizar (ejemplo: comparación de potencia consumida entre diferentes períodos, filtrar consumos por franja horaria, graficar multiples variables y/o dispositivos en el mismo gráfico, etc.).</p>
          <p>NOTA: Si esta herramienta le resulta compleja o difícil de utilizar, le sugerimos primero probar con la sección Instantáneos.</p>

        </Box>

        <Dialog
          className={classes.dialogStyle}
          open={openDataSetFilter}
          onClose={() => setOpenDataSetFilter(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        >
          <DatasetFilter
            statusNodes={statusNodes}
            initialDataset={filter?.groups[indexGroupSelected]?.dataSets[indexDataSetSelected] ?? datasetInitialValues}
            indexGroupSelected={indexGroupSelected}
            indexDataSetSelected={indexDataSetSelected}
            handleDataSetChanges={handleDataSetChanges}
            setOpenDataSetFilter={setOpenDataSetFilter}
            varsCatalog={varsCatalog}
          />
        </Dialog>
        {
          filter?.groups?.map((element, indexGroup) => {
            return (
              <Box key={indexGroup} className={classes.containerGrapSettings}>
                <Box display={'flex'} width={'100%'} justifyContent='space-between' >
                  <Box>
                    <Box >
                      <Box display="flex">
                        <Box
                          className={classes.title}>
                          {element.title}
                        </Box>
                        {indexGroup === 1 &&
                          <Tooltip title="Copiar datasets del gráfico principal">
                            <Button onClick={() => handleCopyDataset()}>
                              <ContentCopyIcon style={{ fontSize: '18px' }} />
                            </Button>
                          </Tooltip>
                        }
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        minHeight={40}
                      >
                        <Tooltip
                          title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                          arrow
                          placement="right"
                        >
                          <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}>
                            1
                          </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>
                          Agregar dataset
                        </Box>
                        <IconButton
                          aria-label="Agregar dataset"
                          className={classes.addBtn}
                          onClick={() => handleOpenDataSet(indexGroup)}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                        {element?.dataSets?.length > 0 &&
                          element?.dataSets.map((dataset, indexDataSet) => {
                            let variable = varsCatalog?.find(item => item.key === dataset.variable);
                            dataset.variableName = variable.name
                            return <Chip
                              key={dataset.id}
                              icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                              label={getChipLabel(dataset)}
                              clickable
                              color="primary"
                              onClick={() => handleOpenDataSet(indexGroup, indexDataSet)}
                              onDelete={() => handleDeleteDataSet(indexGroup, indexDataSet)}
                              className={classes.filterChip}
                            />
                          }
                          )}
                      </Box>
                    </Box>
                    <Box className={classes.wrapperDatepicker}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        minHeight={40}
                      >
                        <Tooltip
                          title="Elija el/los dispositivos que desea añadir al gráfico principal haciendo click en el botón +"
                          arrow
                          placement="right"
                        >
                          <Box className={clsx(classes.hint, classes.leftSpacing, { [classes.active]: activeStep === 0, }, { [classes.tick]: activeStep > 0, })}>
                            2
                          </Box>
                        </Tooltip>
                        <Box className={classes.devicesLabel}>
                          Seleccione fecha
                        </Box>
                        <DatePickerCommon
                          startDate={element.selectedInitialDate}
                          endDate={element.selectedEndDate}
                          handleDateChange={(dateStart, dateEnd) => handleDateChange(dateStart, dateEnd, indexGroup)}
                          setDate={setDate}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Delete icon */}

                  {indexGroup === 1 &&
                    <Box>
                      <IconButton
                        className={classes.deleteIcon}
                        onClick={deleteSecondaryGraph}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Box>
                  }
                </Box>
              </Box>
            )
          })
        }

        {filter?.groups?.length === 1 &&
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            minHeight={40}
          >
            <IconButton
              aria-label="Agregar dataset"
              className={classes.addBtn}
              onClick={addSecondaryGraph}
            >
              <AddIcon fontSize="small" />
            </IconButton>

            <Box ml={1} className={classes.devicesLabel}>
              Agregar gráfico
            </Box>

          </Box>
        }
      </Box >
      {/* Botones */}
      < Box display="flex" justifyContent="center" alignItems="center" >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.btn}
          onClick={handleSubmit}
        >
          Consultar
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          className={classes.btn}
        >
          Cancelar
        </Button>
      </Box >
    </>
  );
}
