import React, { useEffect, useState } from 'react';
import './Styles/sidebar.css';
import { Box, makeStyles } from '@material-ui/core';
//helpers.
import { getCatalogo } from './helpers';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

let scrollsSettings = {
  overflow: 'scroll',
  marginBottom: '10px',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #b5b5b578',
    padding: '5px',
    width: '300px',
    height: '95%',
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 960px)': {
      width: '100%',
    },
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  input: {
    '& .MuiInputBase-input': {
      // Saco las flechitas al input type number
      '&::-webkit-inner-spin-button': {
        webkitAppearance: "none",
        mozAppearance: "none",
        appearance: " none",
        margin: 0
      },
    },
  },
}));

const Sidebar = ({ componentType }) => {
  const classes = useStyles();
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  const [blocks, setBlocks] = useState({})
  const [initialBlocks, seInitialBlocks] = useState({})
  const [inputValue, setInputValue] = useState('')
  //Carga catalogo segun componente PLC, ALERT.
  useEffect(() => {
    let res = getCatalogo(componentType, 'generic_data')
    if (res) {
      let resAux = res['order_blocks']
      seInitialBlocks(resAux)
      setBlocks(resAux)
    }
    // eslint-disable-next-line
  }, [])

  let listNoneSvg = ['FFRS', 'CONST', 'VARIABLE', 'ALERT', 'LEQ', 'EQ',
    'LESS', 'GREATER', 'GEQ', 'ADDER', 'DIV', 'MUL', 'GAIN', 'MOD', 'INA', 'IND', 'OUTA', 'OUTD']

  let elementMap = {
    'FFRS': 'rs',
    'CONST': 'C',
    'VARIABLE': 'V',
    'ALERT': 'A',
    'LEQ': '<=',
    'EQ': '=',
    'LESS': '<',
    'GREATER': '>',
    'GEQ': '>=',
    'ADDER': '+',
    'DIV': '/',
    'MUL': 'x',
    'GAIN': 'G',
    'MOD': '%',
    'INA': 'INA',
    'IND': 'IND',
    'OUTA': 'OTA',
    'OUTD': 'OTD'
  };
  const returStringSVG = (element) => elementMap[element] || '';

  const filterCategories = (data, input) => {
    const palabras = input.toLowerCase().split(/\s+/); // Divide el input en palabras ignorando espacios extra
    let resultado = {};
    for (const categoria in data) {
      // Filtrar los elementos que contienen alguna palabra en .name o .key
      const elementosFiltrados = data[categoria].filter(({ name, key }) =>
        palabras.some(palabra =>
          name.toLowerCase().includes(palabra) || key.toLowerCase().includes(palabra)
        )
      );
      // Si hay elementos filtrados, añadir la categoría al resultado
      if (elementosFiltrados.length > 0) {
        resultado[categoria] = elementosFiltrados;
      }
    }
    return resultado;
  };

  const handleInputChange = (e) => {
    let valueAux = e.target.value
    setInputValue(valueAux)
    setBlocks(filterCategories(initialBlocks, valueAux))
  }

  return (
    <Box>
      <Box width={'100%'} >
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            onChange={handleInputChange}
            placeholder="Buscador"
            value={inputValue}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {
            inputValue.length > 0 ?
              <IconButton
                onClick={() => { setInputValue(''); setBlocks(initialBlocks) }}
                type="button" sx={{ p: '10px' }} aria-label="search">
                < CloseIcon />
              </IconButton>
              :
              <IconButton
                disabled={true} type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
          }
        </Paper>
      </Box>
      <Box
        className={classes.root}
      >
        {Object.entries(blocks).map(([sectionKey, items]) => (
          <Box key={sectionKey}>
            <Box ml={1} mt={1}
              style={{
                fontWeight: 'bold',
                userSelect: 'none'
              }}
            >
              {sectionKey}
            </Box>
            {items?.map((elem, indx) => (
              < Box
                key={indx}
                mt={0.5}
                display={'flex'}
                alignItems={'center'}
                onDragStart={(event) => onDragStart(event, elem.key)}
                draggable
                style={{ cursor: 'pointer' }}
              >
                <Box style={{ display: 'flex', backgroundColor: '#f2c84aa1', width: '22px', height: '22px' }} >
                  {!listNoneSvg.includes(elem?.key) ?
                    <img
                      style={{
                        width: elem.key === 'COUNT' ? '20px' : '25PX',
                        height: '15px',
                        margin: 'auto',
                      }}
                      src={`/images/diagram/${elem?.key?.toLowerCase()}.svg`} alt='svg' />
                    :
                    <Box
                      style={{
                        width: '25px',
                        height: '15px',
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '10px'
                      }}>
                      {returStringSVG(elem?.key)}
                    </Box>
                  }
                </Box>
                <Box
                  style={{
                    backgroundColor: 'white',
                    color: '#2586bc',
                    border: '1px solid #b5b5b578',
                    borderRadius: '3px',
                    padding: '3px',
                    margin: '0 4px 0 5px',
                    width: '280px',
                    fontSize: '12px',
                  }}
                >
                  {elem?.name}
                </Box>
              </Box>
            ))}
          </Box>
        ))
        }
      </ Box >
    </Box>

  );
};

export default Sidebar

