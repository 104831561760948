

import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import {
    Box, IconButton
} from '@material-ui/core';
//Component
import ComponentChildren from './ComponentChildren';
//Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

let scrollsSettings = {
    overflow: 'scroll',
    marginBottom: '10px',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    labelInputSelectVar: {
        fontSize: '16px',
        marginBottom: '3px'
    },
    containerLabelIconOpen: {
        borderBottom: `0.2px solid ${'#00000082'}`,
        cursor: 'pointer'
    },
    containerGroups: {
        ...scrollsSettings,
        maxHeight: '500px',
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 2,
        borderRadius: '2px',
        boxShadow: '10px 8px 25px -2px #02020240',
        width: '70%',
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },

        '@media (max-width: 960px)': {
            width: '80%',
        },
    },
    iconButtonArrowDrow: {
        '&.MuiIconButton-root': {
            paddingRight: 0,
            paddingBottom: 0
        }
    },
    placeHolder: {
        all: 'unset',
        fontSize: '17px',
        color: '#7c7c7d',
    },
    labelSelected: {
        fontSize: '12px',
        color: '#a3a3a4'
    }
}));
let disabledElement = {
    pointerEvents: 'none',
    opacity: 0.5,
    color: '#ccc',
}

const DynamicSelect = ({ section, InputLabelProp, handleChangeProp, keyName, disabledSelect,
    disabledVarianteInstant, valueSelect, disabledVarianteHistoric, showUnit, placeHolder = "", setDisabledInputGranularity, groupListVariables }) => {
    const classes = useStyles();
    const [groupList, setGroupList] = useState([])
    const [openSelect, setOpenSelect] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const componentRef = useRef(null);
    const [groupTitleKey, setGroupTitleKey] = useState('');

    //Verifica si el user clickeo fuera de el componente.
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Toma el value si cambia de la variable seleccionada.
    useEffect(() => {
        setSelectedValue(valueSelect)
    }, [valueSelect]);


    //Esta funcion modifica la  busqueda de la lista de variables en ciertos casos por q la estructura cambia en ciertas secciones.
    const changingCatalogProp = (section) => {
        let res = getCatalogo('sections', section);
        if (section === 'ALERTS_VARIABLES') {
            res = res.detail_data
        }
        return res
    }

    useEffect(() => {
        //si estoy enviando el grupo de variables mostralas. si no hace la busqueda.
        if (groupListVariables?.length > 0) {
            setGroupList(groupListVariables);
        }
        else {
            const groupList = getCatalogo('groups_vars', section);
            const varsList = changingCatalogProp(section)    

            const updatedGroupList = groupList.map(group => {
                const children = varsList.filter(variable => variable.group.key === group.key);
                return {
                    ...group,
                    children: group.children ? [...group.children, ...children] : children
                };
            });
            setGroupList(updatedGroupList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section, groupListVariables]);

    useEffect(() => {
        if (valueSelect) {
            const groupList = getCatalogo('groups_vars', section)
            const varsList = changingCatalogProp(section)
            let varSelected = varsList.find(e => e?.key === valueSelect)
            setSelectedValue([varSelected?.tag ? varSelected?.tag : varSelected?.name])
            let findTtitleGroup = groupList?.find((e) => e?.key === varSelected?.group?.key)
            setGroupTitleKey(findTtitleGroup?.key)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueSelect, section])

    const handleOpen = () => {
        setOpenSelect(true);
    };

    const handleClose = (element) => {
        setOpenSelect(false);
        if (element?.key) {
            let e = {
                target: {
                    name: keyName,
                    value: element.key
                }
            }
            handleChangeProp(e)
            handleChange(element?.tag ? element?.tag : element.name)
        }
    };

    const handleChange = (name) => {
        setSelectedValue([name]);
    };

    // Obtiene el catalogo
    const getCatalogo = (option, section) => {
        let nameVarLocal = 'UI_ROLES'
        let VARS_CATALOGO = JSON.parse(localStorage.getItem(nameVarLocal))?.[option][section]
        return VARS_CATALOGO
    };

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            handleClose()
        }
    };

    return (
        <Box
            display="flex"
            width='100%'
            height={'40px'}
        >
            {/* ICON OPEN CLOSE */}
            {openSelect ||
                <Box display={'flex'} flexDirection={selectedValue && placeHolder ? 'column' : 'row'} width={'100%'}>
                    {(selectedValue && placeHolder) && <Box className={classes.labelSelected}>{placeHolder}</Box>}
                    <Box
                        className={classes.containerLabelIconOpen}
                        onClick={handleOpen}
                        style={{ ...(disabledSelect ? disabledElement : {}) }}
                        width={'90%'} display='flex' justifyContent={'space-between'} alignItems={'flex-end'}>
                        {(!selectedValue && placeHolder) && <input value={placeHolder} className={classes.placeHolder} />}
                        <Box
                            className={classes.labelInputSelectVar}
                        >
                            {disabledSelect ? '' : selectedValue}
                        </Box>
                        <IconButton
                            className={classes.iconButtonArrowDrow}
                            onClick={handleOpen}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    </Box>
                </Box>
            }
            {/* INPUT SELECTOR DISEÑADO A MANO */}
            {
                openSelect &&
                <Box
                    ref={componentRef}
                    className={classes.containerGroups}
                    width={'100%'}>
                    {groupList.map((element) => {
                        return (
                            <Box key={element.key}>
                                <ComponentChildren
                                    selectedValue={selectedValue}
                                    groupTitleKey={groupTitleKey}
                                    setGroupTitleKey={setGroupTitleKey}
                                    disabledVarianteInstant={disabledVarianteInstant}
                                    disabledVarianteHistoric={disabledVarianteHistoric}
                                    handleClose={handleClose}
                                    element={element}
                                />
                            </Box>

                        )
                    })}
                </Box>
            }
        </Box >
    )
}
export default DynamicSelect

