import React, { useState } from 'react';
import { Box, makeStyles, Snackbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
  }
}))


const PaymentPendingBanner = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box >
      <Snackbar open={open} onClose={handleClose} className={classes.container}>
      
      </Snackbar>
    </Box>
  );
};

export default PaymentPendingBanner;
