import React, { useEffect, useState, useContext } from 'react';
//Material ui.
import {
    makeStyles, Box,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from '@material-ui/core';
//componente
import TopNav from '../common/TopNav';
//Componentes
import Container from '../Elements/Container';
import AlertComponent from '../Elements/AlertComponent';
import UserContext from '../../context/UserContext';
import CardComponent from './CardComponent';
import ModalPay from './ModalPay';
//Servicios
import { changeStateOrder, getpaymentInfo, purchasePlan } from '../../services/payments';
import { getNotificationsNumber } from '../../services/notifications';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { Link } from 'react-router-dom';
import  PaymentPendingBanner from '../Banners/PaymentPendingBanner'

const useStyles = makeStyles(theme => ({
    containerPlans: {
        padding: theme.spacing(5),
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        "@media (max-width: 1400px)": {
            flexWrap: 'wrap',
            marginLeft: theme.spacing(1),
            overflow: "hidden",
        },
    },
    textPrimary: {
        color: theme.palette.primary.main
    }
}))

export default function Payments() {
    const classes = useStyles()
    const { plan } = useContext(UserContext);
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [loading, setLoading] = useState(false)
    const [purchasingPlan, setPurchasingPlan] = useState(false)
    const [purchasing, setIsPurchasing] = useState(false)
    const [plans, setPlans] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const [openModalPay, setOpenModalPay] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [notificationsPendings, setNotificationsPendings] = useState(null)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    useEffect(() => {
        getPayments()
    }, [])

    const getPayments = async () => {
        setLoading(true)
        try {
            const notifications = await getNotificationsNumber()
            setNotificationsPendings(notifications?.data)
            const response = await getpaymentInfo()
            setUserInfo(response?.data)
            setPlans(response?.data?.plans)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            setMsg({
                msg: "Error al cargar la información de los planes",
                type: "error"
            })
            setClose(true)
        }
    }

    const handleOpenCloseModalPay = (plan) => {
        setSelectedPlan(plan)
        setOpenModalPay(!openModalPay)
    }

    const handlePurchase = async (purchase) => {
        setPurchasingPlan(true)
        let res = await purchasePlan(purchase)
        setPurchasingPlan(false)
        if (res) {
            openLinkInNewTab(res?.data?.link_pago)
            setOpenModalPay(false)
        }
    }

    const openLinkInNewTab = (link) => {
        setIsPurchasing(true)
        window.open(link, '_blank')
    }

    const handleCancelOrder = async () => {
        let order = {
            compra_id: notificationsPendings.last_compra,
            estado: 2
        }
        try {
            await changeStateOrder(order)
            setMsg({
                msg: "Órden cancelada correctamente",
                type: "success"
            })
            setClose(true)
            setOpenConfirmationDialog(false)
            getPayments()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {/* <PaymentPendingBanner /> */}

            <ModalPay openModalPay={openModalPay} handleOpenCloseModalPay={handleOpenCloseModalPay} selectedPlan={selectedPlan}
                userInfo={userInfo} handlePurchase={handlePurchase} purchasingPlan={purchasingPlan} />

            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <TopNav titulo="Pagos" subtitulo='| Planes' />

            <Container >
                <Box display={'flex'} justifyContent={'space-between'} mt={3} mx={6} alignItems={'start'}>                    
                    <Box>
                        <Typography variant={"h5"}>Plan actual: <span className={classes.textPrimary}>{userInfo?.info_user?.plan_actual}</span></Typography>
                        {userInfo.info_user?.dias_restantes && <Typography variant={"body1"}>Días restantes: {userInfo.info_user?.dias_restantes}</Typography>}
                    </Box>
                    <Button variant="contained" color="primary" startIcon={<CreditCardIcon />} onClick={() => handleOpenCloseModalPay()}>
                        <Link to="/compras" style={{ textDecoration: 'none', color: 'white' }}>
                            Historial de compras
                        </Link>
                    </Button>
                </Box>

                {loading &&
                    <Box mt={6} display='flex' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                }

                {notificationsPendings?.estado_compra === "Pendiente de Pago" ?

                    <Alert severity="info" variant="outlined" sx={{ maxWidth: 600, mx: "auto", p: 2, borderRadius: 2, marginTop: 5, width: '100%' }}>
                        <AlertTitle>Pago pendiente</AlertTitle>
                        <p>
                            Tienes un pago pendiente. Por favor realiza el pago o cancela la órden para continuar.
                        </p>
                        <Stack direction="row" spacing={2} mt={2} justifyContent={"flex-end"} width={"100%"}>
                            <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => setOpenConfirmationDialog(true)}>
                                Cancelar orden
                            </Button>

                            <Button variant="contained" color="primary" startIcon={<CreditCardIcon />} onClick={() => openLinkInNewTab(notificationsPendings.link_pago)}>
                                Pagar
                            </Button>
                        </Stack>
                    </Alert>

                    :
                    <Box width={'100%'} display='flex' justifyContent='center' >
                        <Box className={classes.containerPlans}  >
                            {plans.map((plan) => {
                                return (
                                    <CardComponent handleOpenCloseModalPay={handleOpenCloseModalPay} userInfo={userInfo} key={plan?.id} plan={plan} />
                                )
                            })}
                        </Box>
                    </Box>
                }
            </Container >


            <Dialog open={purchasing} >
                <DialogTitle>Compra de nuevo plan</DialogTitle>
                <DialogContent>Te redirigimos a la página de pago, estamos validando que se efectúe el pago para continuar...</DialogContent>
                <Box my={6} display='flex' justifyContent='center'>
                    <CircularProgress />
                </Box>
                <DialogActions>
                    <Button onClick={() => {
                        setIsPurchasing(false)
                        getPayments()
                    }}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <ConfirmationDialog
                open={openConfirmationDialog}
                setOpen={setOpenConfirmationDialog}
                title={"¿Está seguro de cancelar la órden?"}
                content={"En caso de continuar se cancelará la órden anteriormente creada y podrá seleccionar otro plan"}
                handleConfirmation={handleCancelOrder}
            />

        </>
    )
}

