import instance from './testing.instance';
export const getPlc_automate_24v_list = () => instance.get(`/automates/`);
export const getPlc_automate_24v_state = (id_automate, id_diagram) => instance.get(`/automates/${id_automate}/diagram/${id_diagram}/state-diagram/`);
export const getPlc_automate_24v_byId = (id) => instance.get(`/automates/${id}/`);
export const postPlc_automate_24v_byId = (params_id, body) => instance.post(`/automates/${params_id}/diagram/`, body);
export const putPlc_automate_24v_byId = (params_id, id_diagram, body) => instance.put(`/automates/${params_id}/diagram/${id_diagram}/`, body);
//Edita el estado de el diagrama STOP, PAUSA, PLAY
export const putPlc_automate_24v_state_action = (id_automate, body) => instance.put(`/automates/${id_automate}/action-state/`, body);
//Descarga archivo json o txt Automate
export const getExportDiagramAutomate = (id_automate, id_diagram) => instance.get(`/automates/${id_automate}/diagram/${id_diagram}/export-diagram-plc/ `);
//Descarga archivo plc diagrama Automate
export const getExportDiagramPlcFormatAutomate = (id_automate, id_diagram) => instance.get(`/automates/${id_automate}/diagram/${id_diagram}/export-translation-diagram/`);
//Edita diagrama en Automate.
export const putDiagramInAutomate = (id_diagram, body) => { instance.put(`/diagram-free/${id_diagram}/update-automates-diagrams/`, body) };
//Ver el estado de la carga del diagrama en el dispositivo
export const getStateSendDiagram = (id_automate) => instance.get(`/automates/${id_automate}/state-send-diagram/`);
//Ver si tenemos conexion con el dispositivo plc
export const getDeviceConnectionStatus = (id_automate) => instance.get(`/automates/${id_automate}/verify-diagram/`);
//Lista de automates sin asignar a ningun nodo.
export const getAutomateFree = () => instance.get(`/automates?unassigned=true`);
//Verifica el estado del automate si, tiene conexion o no..
export const getStateAutomate = (id_automate) => instance.get(`/automates/${id_automate}/state-plc-diagram/`);

