import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

export default function ConfirmationDialog({ title, content, open, setOpen, handleConfirmation }) {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button color="default" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    onClick={handleConfirmation}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    )
}