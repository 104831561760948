import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TotalModalPay from './TotalModalPay';
import { CircularProgress } from '@material-ui/core';

const ProductSelectorDialog = ({
  openModalPay,
  handleOpenCloseModalPay,
  handlePurchase,
  selectedPlan,
  userInfo,
  purchasingPlan,
}) => {
  const [products, setProducts] = useState([
    { id: 1, name: 'Perk 1', quantity: 0, price: 8264.46 },
    { id: 2, name: 'Perk 2', quantity: 0, price: 8264.46 },
    { id: 3, name: 'Perk 3', quantity: 0, price: 1652.89 },
    { id: 4, name: 'Perk 4', quantity: 0, price: 8761 },
    { id: 5, name: 'Perk 5', quantity: 0, price: 1652.89 },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const MONTHLY = 1;
  const ANNUAL = 2;
  const [planType, setPlanType] = useState(MONTHLY);
  const annualMultiplier = userInfo?.info_user?.multiplicador_anual;

  useEffect(() => {
    let productsAux = [...products];
    productsAux.forEach(
      product =>
        (product.newPrice = product.price * (planType === ANNUAL ? annualMultiplier : 1))
    );
    setProducts(productsAux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planType]);

  const updateQuantity = (id, change) => {
    setProducts(
      products.map(product =>
        product.id === id
          ? { ...product, quantity: Math.max(0, product.quantity + change) }
          : product
      )
    );
  };

  const save = () => {
    let purchase = {
      plan: selectedPlan.id,
      perks: [],
      perks_qty: [],
      duracion: planType,
    };
    handlePurchase(purchase);
  };

  const handlePlanChange = (_, newValue) => {
    if (newValue) {
      setPlanType(newValue);
    }
  };

  return (
    <Dialog
      open={openModalPay}
      onClose={handleOpenCloseModalPay}
      fullWidth
      maxWidth="md"
    >
      <Box p={5} sx={{ borderRadius: 2, backgroundColor: '#FAFAFA' }}>
        <Typography
          variant="h5"
          textAlign="center"
          fontWeight="bold"
          color="primary"
        >
          Plan {selectedPlan?.name}
        </Typography>

        <Stepper activeStep={activeStep} sx={{ my: 3 }}>
          <Step>
            <StepLabel>Seleccionar productos</StepLabel>
          </Step>
          <Step>
            <StepLabel>Vista previa</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 ? (
          <>
            <Box display="flex" justifyContent="center" my={3}>
              <ToggleButtonGroup
                value={planType}
                exclusive
                onChange={handlePlanChange}
                color="primary"
              >
                <ToggleButton value={MONTHLY}>Mensual</ToggleButton>
                <ToggleButton value={ANNUAL}>Anual</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box display="flex" alignItems="center" mb={2}>
              <CheckCircleIcon color="success" sx={{ mr: 1, fontSize: 22 }} />
              <Typography fontSize={18} fontWeight="bold">
                Perks
              </Typography>
            </Box>

            <Grid container spacing={2} px={4}>
              {products.map(product => (
                <Grid item xs={12} sm={4} key={product.id}>
                  <Card
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      boxShadow: 3,
                      borderRadius: 2,
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        {product.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        $
                        {product.newPrice?.toLocaleString('es-AR', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        ARS
                      </Typography>
                    </CardContent>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'center'}
                    >
                      <IconButton
                        onClick={() => updateQuantity(product.id, -1)}
                        color="error"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        value={product.quantity}
                        size="small"
                        sx={{ width: 50 }}
                        inputProps={{ readOnly: true }}
                      />
                      <IconButton
                        onClick={() => updateQuantity(product.id, 1)}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ my: 3 }} />

            <TotalModalPay
              planType={planType}
              products={products}
              selectedPlan={selectedPlan}
              credit={userInfo?.info_user?.credito}
              annualMultiplier={annualMultiplier}
            />
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              textAlign="center"
              fontWeight="bold"
              mb={2}
            >
              Resumen de tu selección
            </Typography>

            <Grid container spacing={2} px={4}>
              {products.filter(p => p.quantity > 0).length > 0 ? (
                products
                  .filter(p => p.quantity > 0)
                  .map(product => (
                    <Grid item xs={12} sm={6} key={product.id}>
                      <Card
                        sx={{
                          p: 2,
                          textAlign: 'center',
                          boxShadow: 3,
                          borderRadius: 2,
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" fontWeight="bold">
                            {product.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Cantidad: {product.quantity}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Total: $
                            {(product.newPrice * product.quantity).toFixed(2)}{' '}
                            ARS
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  width="100%"
                >
                  No has seleccionado ningún perk.
                </Typography>
              )}
            </Grid>

            <Divider sx={{ my: 3 }} />

            <TotalModalPay
              planType={planType}
              products={products}
              selectedPlan={selectedPlan}
              credit={userInfo?.info_user?.credito}
              annualMultiplier={annualMultiplier}
            />
          </>
        )}

        <Divider sx={{ my: 3 }} />
        <Box display="flex" justifyContent="end" gap={2}>
          {activeStep > 0 && (
            <Button
              onClick={() => setActiveStep(activeStep - 1)}
              variant="outlined"
            >
              Atrás
            </Button>
          )}
          {activeStep < 1 ? (
            <Button
              onClick={() => setActiveStep(activeStep + 1)}
              variant="contained"
            >
              Siguiente
            </Button>
          ) : purchasingPlan ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Button onClick={save} variant="contained" color="primary">
              Confirmar compra
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProductSelectorDialog;
