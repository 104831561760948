import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const DATASET_INITIAL_VALUES = {
    devices: [],
    alias: '',
    variable: '',
    granularity: '',
    timeslots: '',
    color: '',
};



export const abbreviateVariable = (variable) => {
    switch (variable) {
        case "Potencia activa total": return "Pot. Activa Total";
        case "Potencia reactiva total": return "Pot. Reactiva Total";
        case "Potencia activa por fase": return "Pot. Activa x Fase";
        case "Potencia reactiva por fase": return "Pot. Reactiva x Fase";
        case "Tension por fase": return "Tensión x Fase";
        case "Corriente por fase": return "Corriente x Fase";
        case "Potencia adquirida total": return "Pot. Adquirida Total";
        case "Energia activa total": return "Energía Activa Total";
        case "Temperatura total": return "Temp. Total";
        case "Energia total por hora": return "Energía Total x Hora";
        case "Potencia activa consumida total": return "Pot. Activa Consumida";
        case "Potencia activa generada total": return "Pot. Activa Generada";
        case "Energía activa importada total": return "Energía Activa Importada";
        case "Energía activa exportada total": return "Energía Activa Exportada";
        case "Energía activa generada total": return "Energía Activa Generada";
        case "Energía activa consumida total": return "Energía Activa Consumida";
        case "Energía activa autoconsumida total": return "Energía Activa Autoconsumida";
        default: return variable;
    }
}

export const getChipLabel = (dataset) => {
    let name = `${dataset.devices[0].nombre} ${dataset.devices[0].label_nombre || ''}`;
    let variable = dataset.variableName;
    let timeslot = dataset.timeslots ? <AccessTimeIcon style={{ fontSize: '18px' }} /> : null;

    return (
        <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {name} | {abbreviateVariable(variable)} {timeslot}
        </span>
    );
};