import React, { useEffect, useState, useRef } from "react";
import {
    Box, Button, makeStyles, Dialog, TextField
} from '@material-ui/core';
//Servicios.
import {
    createDiagram, getDiagramList,
} from "../../services/diagrams";

import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(1),
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    dialogSubtitle: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2),
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    inputName: {
        margin: theme.spacing(1, 0, 1, 0),
    },
    errorLabel: {
        color: "red",
        fontSize: theme.spacing(2),
    },
    content: {
        maxWidth: '100%',            // Asegura que el contenido no se expanda más allá del ancho disponible
        fontSize: 14,                // Tamaño de fuente
        color: '#5c5d5d',            // Color del texto
        wordWrap: 'break-word',      // Rompe las palabras largas para ajustarlas al contenedor
        overflowWrap: 'break-word',  // Rompe las palabras largas en el contenido
        whiteSpace: 'normal',        // Permite que el texto se apile en lugar de desbordar 
    },
}));

export default function ModalEditDiagram({ nodes, edges, setEditName, editName, diagramName, setRefresh }) {
    const classes = useStyles();
    const [inputState, setInputState] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const history = useHistory();
    const inputRef = useRef(null); // Referencia al input

    useEffect(() => {
        getDiagramListFtn()
    }, [diagramName])

    //Selecciona el input directamente.
    useEffect(() => {
        setTimeout(() => inputRef?.current?.select(), 0); // Seleccionar texto al abrir modal
    }, [editName]);

    const getDiagramListFtn = async () => {
        let res = await getDiagramList()
        let string = diagramName
        let flag = res?.data?.results?.some(item => item.name === `Copia de ${string}`)
        if (flag) {
            string += 'copy'
        }
        if (string?.length > 0) {
            setInputState("Copia de " + string)
        }
    }

    const validateForm = (string) => {
        if (string.length < 5) {
            setErrorMsg("El nombre debe poseer más de 4 caracteres")
            return
        }
        setErrorMsg("")
        return true
    }

    const handleChange = (e) => {
        let name = e.target.value
        setInputState(name)
    }

    const save = async () => {
        let spaceClean = inputState.trim()
        let nameAux = spaceClean
        if (validateForm(nameAux)) {
            let body = {
                "type_plc": "Automate-24V-4M",
                name: inputState,
                edges: edges,
                nodes: nodes
            }
            let res = await createDiagram(body)
            history.push(`/diagram/${res?.data?.id}`)
            setRefresh(prevRefresh => !prevRefresh);
        }
    }

    return (
        <Dialog
            open={editName}
            onClose={() => setEditName(false)}
            aria-labelledby="modal_name"
            fullWidth
            disableScrollLock
        >
            <Box className={classes.containerMain}>
                <Box mt={1} className={classes.dialogSubtitle}>
                    Crear nuevo  diagrama
                </Box>
                <Box className={classes.content} ml={1} mt={1} mb={1}>
                    Antes de enviar un nuevo diagrama, es necesario realizar una copia y guardarla. Esto asegurará que puedas editar y gestionar la nueva versión sin perder la información original.
                </Box>

                <TextField
                    inputRef={inputRef} // Asignamos la referencia
                    className={classes.inputName}
                    type="text"
                    fullWidth
                    label='Asigne un nombre a la copia del diagrama'
                    name='dashboard_name'
                    size='small'
                    variant='outlined'
                    color='primary'
                    value={inputState}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <div className={classes.errorLabel}>
                    {errorMsg}
                </div>

                <Box display='flex' justifyContent='center'>
                    <Button
                        onClick={save}
                        variant='contained' color='primary' className={classes.btnSpacing}>
                        Guardar
                    </Button>

                    <Button
                        onClick={() => setEditName(false)}
                        variant='contained'
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>
        </Dialog >
    )
}