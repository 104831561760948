import React, { useState, useEffect } from 'react';
//Material ui
import {
	Collapse, IconButton, Box,
	List, ListItem,
	ListItemText, makeStyles, Tooltip
} from '@material-ui/core';
//Icons
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SpeedIcon from '@material-ui/icons/Speed';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SyncAltSharpIcon from '@mui/icons-material/SyncAltSharp';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

//Style
import clsx from 'clsx';

let locationMobileStyles = {
	height: "50px",
	padding: "10px",
	marginBottom: "1px"
}
const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
		"@media (max-width: 700px)": {
			position: "relative",
			left: "-32px",
			width: 'calc(100% + 32px)',
		},
	},
	selected: {
		'&.MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white,
		},

		'&.MuiListItem-root.Mui-selected p': {
			color: theme.palette.common.white,
		},
	},
	nodeHeader: {
		display: 'flex',
		'& span': {
			fontSize: "larger",
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
		'& p': {
			marginLeft: '5px',
			fontSize: "larger"
		},
		"@media (max-width: 700px)": {
			flexDirection: " column",
			'& span': {
				marginLeft: '5px',
				fontSize: "small",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
			},
			'& p': {
				marginLeft: '5px',
				fontSize: "small",
			},
		},

	},

	empresa: {
		"@media (max-width: 700px)": {
			// backgroundColor: theme.palette.primary.main,
			...locationMobileStyles
		},
	},
	zona: {
		"@media (max-width: 700px)": {
			// background: "#c1c1c1",
			paddingLeft: theme.spacing(1),
			...locationMobileStyles
		}
	},
	localizacion: {
		"@media (max-width: 700px)": {
			// background: "#e0e0e0",
			paddingLeft: theme.spacing(2),
			...locationMobileStyles
		}
	},
	sublocaclizacion: {
		"@media (max-width: 700px)": {
			// background: "#f1efef",
			paddingLeft: theme.spacing(3),
			...locationMobileStyles
		}
	}
}));

export default function MultiNode({ node, selectedNodes, handleSelectedNode, statusNodes
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const [statusNode, setStatusNode] = useState(true);
	//Verifica status de el medidor.
	useEffect(() => {
		if (!node?.virtual) {
			let nodeId = node?.id
			let nodeAux = statusNodes?.find(e => e.node_id === nodeId)
			setStatusNode(nodeAux?.online)
		}
	}, [statusNodes, node])

	const handleArrows = () => {
		setOpen(!open);
	};

	const classNodeSelect = (node) => {
		if (node.level_nombre === "empresa") {
			return classes.empresa
		}
		else if (node.level_nombre === "zona") {
			return classes.zona
		}
		else if (node.level_nombre === "localización") {
			return classes.localizacion
		}
		else if (node.level_nombre === "sublocalización") {
			return classes.sublocaclizacion
		}
		else {
			return ""
		}
	}

	const disabledNode = () => {
		let validNode = node.valid
		let disabledSelection = node.virtual_device_selection
		if (!validNode || (!disabledSelection && node.virtual)) {
			return true
		}
		return false
	}

	return (
		<>
			<ListItem
				button
				selected={selectedNodes?.some(selected => selected.id === node.id)}
				className={clsx(classes.selected, classNodeSelect(node))}
				onClick={() => handleSelectedNode(node)}
				disabled={disabledNode()}
			>
				{!node.owner && (
					<Tooltip title={`El nodo ${node.nombre} es compartido`}
					>
						<IconButton edge='end' aria-label='nodo compartido' size='small' color="primary">
							<VisibilityIcon />
						</IconButton>
					</Tooltip>
				)}

				<ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={clsx(classes.nodeHeader)}
				/>

				{/* Icono usuario generador*/}
				{node.es_generador &&
					<Tooltip title={`Usuario generador`}>
						<IconButton
							edge='end'
							aria-label='es generador'
						>
							<WbSunnyIcon style={{ color: '#F59500' }} />
						</IconButton>
					</Tooltip>
				}

				{/* Icono bidireccional */}
				{node.es_bidireccional &&
					<Tooltip title={`Bidireccional`}>
						<IconButton
							edge='end'
							aria-label='bidireccional'
						>
							<SyncAltSharpIcon style={{ color: '#0000008a' }} />
						</IconButton>
					</Tooltip>
				}

				{/* Icon automate */}
				{node?.automates?.length > 0 &&
					<Tooltip title={`${node.nombre} tiene un automate asociado`}>
						<IconButton
							edge='end'
							aria-label='automates asociados'
						>
							<PrecisionManufacturingIcon style={statusNode?.id === node?.id ? { color: '#d8d8d8' } : {}} />
						</IconButton>
					</Tooltip>
				}

				{/*  Icon medidor fisico */}
				{node.medidor && <Box width={30}
					className={clsx(classes.iconMedidor)}>
					<Tooltip title={`${node.nombre} tiene un medidor asociado`}>
						<IconButton	>
							<SpeedIcon style={statusNode ? { color: '#66bb6a' } : { color: '#ef5350' }} />
						</IconButton>
					</Tooltip>
				</Box>}

				{/*  Icon open close */}
				{node.hijos &&
					node.hijos.length > 0 &&
					<Box width={30}>
						{(open ? (
							<IconButton edge='end' aria-label='contraer' onClick={handleArrows}>
								<ExpandLess />
							</IconButton>

						) : (

							<IconButton edge='end' aria-label='expandir' onClick={handleArrows}>
								<ExpandMore />
							</IconButton>
						))}
					</Box>}
			</ListItem>

			{node.hijos ? (
				<Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
					<List dense component='div' disablePadding>
						{node.hijos.map(c => (
							<MultiNode
								node={c}
								key={c.id}
								handleSelectedNode={handleSelectedNode}
								selectedNodes={selectedNodes} statusNodes={statusNodes}
							/>
						))}
					</List>
				</Collapse>
			) : null}
		</>
	);
}
