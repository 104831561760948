import ContainerBlockGenericTypeLogic from "../ContainersBlocks/ContainerBlockGenericTypeLogic";

function ADDER
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericTypeLogic
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            userConfiguration={true}
            borderRadius={true}            
        />
    );
}
export default ADDER