
import { useContext, useState, useEffect } from 'react';
import {
    Button, Dialog, Box, makeStyles, TextField
} from '@material-ui/core';
import { useReactFlow, useNodes, useEdges } from 'reactflow';
//Hook personalizado.
import DiagramContext from "./Store/DiagramContext";
//Componentes.
import InputsFilterOptions from './FilterOptions/InputsFilterOptions';
import GenericFilterOptions from './FilterOptions/GenericFilterOptions';
import MbsFilterOptions from './FilterOptions/MbsFilterOptions';
import MbrFilterOptions from './FilterOptions/MbrFilterOptions';
import AlertFilterOptions from './FilterOptions/AlertFilterOptions';
import VariableFilterOptions from './FilterOptions/VariableFilterOptions';
import DynamicFilterOptions from './FilterOptions/DynamicFilterOptions';
//Libreria
import { MarkdownRenderer } from './FilterOptions/helperDescriptionText';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    containerMain: {
        ...scrollsSettings,
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    btnSpacing: {
        margin: theme.spacing(0, 2),
    },

}));

const ModalSettings = ({ id, handleOpenModal, openModal, setHandleSourceQuantity, handleSourceQuantity, type, blockNodeSettings, userConfiguration }) => {
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext);
    const classes = useStyles();
    const reactFlow = useReactFlow();
    const nodes = useNodes()
    const edges = useEdges();
    const [disabledButtonSave, setDisabledButtonSave] = useState(true)
    const [disableEditing, setDisableEditing] = useState(false)
    //Estado de bloque alarma.
    const [alertState, setAlertState] = useState({
        name: '',
        frequency: 20,
        activate: false
    })
    //Estado de bloque variable.
    const [varState, setVarState] = useState({
        values: 'SECCION-VARIABLE',
        nodes: [],
        variable_operation: ''
    });
    const [lastValueInputsSettings, setLastValueInputsSettings] = useState({

    })

    useEffect(() => {
        //Funcion q verifica si el usuario esta en la ruta '/dashboard-publico.'
        const chekUrlPublic = () => {
            //Validar estar en ruta 'dashboard-public'.
            const ruta = window.location.pathname;
            const disabledDrawer = ruta.split('/')[1]
            let flagBooleano = false
            if (disabledDrawer === 'plc') {
                flagBooleano = true
            }
            else {
                flagBooleano = false
            }
            setDisableEditing(flagBooleano)
        }
        chekUrlPublic()
    })

    useEffect(() => {
        let alertSettings = stateDiagram?.alertObject?.alert
        setAlertState({
            name: alertSettings?.name,
            frequency: alertSettings?.frequency,
            activate: alertSettings?.notification
        })
    }, [stateDiagram?.alertObject?.alert])

    useEffect(() => {
        let findNode = [...nodes].find(e => e.id === id)
        if (findNode) {
            setVarState(findNode)
        }
        // eslint-disable-next-line
    }, [])

    //Funcion q se encarga de eliminar los edges pertenecientes a salidas q ya no existan por q el user cambio la cantidad de ellas.
    function filterByTargetHandleAndId(arr, num, targetId) {
        return arr.filter(obj => obj.targetHandle <= num || obj.target !== targetId);
    }

    const deleteEdges = (element) => {
        setHandleSourceQuantity(lastValueInputsSettings?.newSelectedNumber);
        let arryEdited = [...nodes].map((elem) => {
            if (elem.id === id) {
                elem.inputsQuantity = lastValueInputsSettings?.newSelectedNumber
            }
            return elem
        })
        reactFlow.setEdges(filterByTargetHandleAndId(edges, element?.value, element?.blockId))
        reactFlow.setNodes(arryEdited)
    }

    const save = (type) => {
        //Cierra el modal.
        handleOpenModal()
        if (type === 'ALERT') {
            setStateDiagram((prevState) => ({
                ...prevState,
                alertObject: {
                    ...prevState?.alertObject,
                    alert: alertState
                }
            }))
        }
        else {
            let newproperties = varState
            let arrayEdited = [...nodes].map((elem) => {
                if (id === elem?.id) {
                    elem = {
                        ...elem,
                        ...newproperties
                    }
                    return elem
                }
                else {
                    return elem
                }
            })
            reactFlow.setNodes(arrayEdited)
        }
        cleanErrorBlockNode(varState)
        stateDiagram.setDisabledButtonChargeDiagramInAutomate(true)
        //Si un bloque con configuracion de entradas cambio su settings de entradas, se eliminan los edges q no cumplen con la nueva configuracion.
        if (lastValueInputsSettings?.value) {
            deleteEdges(lastValueInputsSettings)
        }
    }

    const cleanErrorBlockNode = (varState) => {
        let res = stateDiagram?.errorBlockNodes?.includes(varState?.id)
        if (res) {
            let newArry = [...stateDiagram.errorBlockNodes].filter(e => e !== varState?.id)
            setStateDiagram({
                ...stateDiagram,
                errorBlockNodes: newArry
            })
        }
    }

    //Funcion q valida el type de el componente.
    const validateAndComponent = () => {
        return ['AND', 'OR', 'NAND', 'XNOR', 'NOR', 'XOR', 'ADDER', 'MUL'].includes(type);
    };


    const handleTitleDescriptionBlock = (e) => {
        let { value } = e.target
        setVarState({
            ...varState,
            n: value
        })
    }

    return (
        <Dialog
            open={openModal}
            maxWidth={'lg'}
            onClose={handleOpenModal}
            // maxWidth={type === 'VARIABLE' || type === 'ALERT' || type === 'TCRON' ? "lg" : 'sm'}
            fullWidth
            disableScrollLock
        >
            <Box className={classes.containerMain}>

                <Box mb={2} >
                    <Box className={classes.content} ml={1}>
                        <MarkdownRenderer markdown={blockNodeSettings?.description ? blockNodeSettings?.description : ''} />
                    </Box>
                </Box>

                <Box >
                    <Box mt={2} >
                        <TextField
                            disabled={disableEditing}
                            fullWidth
                            // type={"number"}
                            name={'n'}
                            label={'Título descriptivo'}
                            size='small'
                            variant='outlined'
                            value={typeof varState?.n === "string" ? varState?.n : type}
                            onChange={handleTitleDescriptionBlock}
                            color='primary'
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>

                {/* BLOQUES */}
                {
                    type === 'ALERT' ?
                        //Componente con los selectores para el bloque 'ALERTA'
                        <AlertFilterOptions
                            setDisabledButtonSave={setDisabledButtonSave}
                            setAlertState={setAlertState}
                            alertState={alertState}
                            stateDiagram={stateDiagram}
                        />
                        :
                        type === 'VARIABLE' ?
                            //Componente con los selectores para el bloque 'VARIABLE'
                            <VariableFilterOptions
                                setDisabledButtonSave={setDisabledButtonSave}
                                setVarState={setVarState}
                                varState={varState}
                            />
                            :
                            type === 'MBS' ?
                                //Componente con los selectores para el bloque 'MBS'
                                <MbsFilterOptions
                                    disableEditing={disableEditing}
                                    blockNodeSettings={blockNodeSettings}
                                    typeBlock={type}
                                    id={id}
                                    setVarState={setVarState}
                                    varState={varState}
                                />
                                :
                                type === 'MBR' ?
                                    //Componente con los selectores para el bloque 'MBS'
                                    <MbrFilterOptions
                                        disableEditing={disableEditing}
                                        blockNodeSettings={blockNodeSettings}
                                        typeBlock={type}
                                        id={id}
                                        setVarState={setVarState}
                                        varState={varState}
                                    />
                                    :
                                    type === 'PID' || type === 'RAMP' ?
                                        <DynamicFilterOptions
                                            disableEditing={disableEditing}
                                            blockNodeSettings={blockNodeSettings}
                                            typeBlock={type}
                                            id={id}
                                            setVarState={setVarState}
                                            varState={varState}
                                        />
                                        :
                                        validateAndComponent() ?
                                            //  {/*'AND', 'OR', 'NAND', 'XNOR', 'NOR', 'XOR', 'ADDER', 'MULT'*/}
                                            <InputsFilterOptions
                                                disableEditing={disableEditing}
                                                blockNodeSettings={blockNodeSettings}
                                                typeBlock={type}
                                                setDisabledButtonSave={setDisabledButtonSave}
                                                setHandleSourceQuantity={setHandleSourceQuantity}
                                                handleSourceQuantity={handleSourceQuantity}
                                                id={id}
                                                setLastValueInputsSettings={setLastValueInputsSettings}
                                            />
                                            :
                                            // Bloques q no entran en la validación de arriba.
                                            < GenericFilterOptions
                                                disableEditing={disableEditing}
                                                typeBlock={type}
                                                id={id}
                                                blockNodeSettings={blockNodeSettings}
                                                setDisabledButtonSave={setDisabledButtonSave}
                                                nodes={nodes}
                                                setVarState={setVarState}
                                                varState={varState}
                                                stateDiagram={stateDiagram}
                                            />
                }

                {/* Botones */}
                {
                    userConfiguration && !disableEditing &&
                    <Box m={2} mt={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Button
                            onClick={() => save(type)}
                            variant="contained"
                            color="primary"
                        // disabled={disabledButtonSave}
                        >
                            Guardar
                        </Button>
                        <Button
                            onClick={handleOpenModal}
                            variant='contained'
                            className={classes.btnSpacing}>
                            Cancelar
                        </Button>
                    </Box>
                }
            </Box>
        </Dialog >
    )
}
export default ModalSettings
