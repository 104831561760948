import { Box, CircularProgress, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getpaymentInfo } from "../../services/payments";
import TopNav from "../common/TopNav";
import Container from '../Elements/Container';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-container': {
            '@media (max-width: 1100px)': {
                margin: '0px 0px 0 0',
            }
        },
        '& .MuiDialog-paper': {
            margin: 0
        },
        '& .MuiDialog-paperScrollPaper': {
            color: '#423d3dde',
            maxHeight: '100vh',
            '@media (max-width: 900px)': {
                width: '100%',
                height: '100%',
            }
        }
    },
    title: {
        padding: theme.spacing(1, 0, 1, 0),
        color: '#5d5959ed',
        fontSize: 15,
        marginRight: 5,
        '@media (max-width: 900px)': {
            fontSize: 12,
        }
    },
    table: {
        marginTop: 10,
        padding: 5,
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    header: {
        background: theme.palette.primary.main,
    },
    headerCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    noResultsBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        textAlign: 'center',
    },
}));

export default function HistoryPayments() {
    const [loading, setLoading] = useState(false)
    const [purchases, setPurchases] = useState([])

    useEffect(() => {
        getPayments()
    }, [])

    const getPayments = async () => {
        setLoading(true)
        try {
            const response = await getpaymentInfo()
            setPurchases(response?.data?.compras)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            // setMsg({
            //     msg: "Error al cargar la información",
            //     type: "error"
            // })
        }
    }

    const labelsTableHeader = ['Plan', 'Cantidad de perks', 'Duración', 'Fecha de creación', 'Fecha de pago', 'Estado'];
    const classes = useStyles();

    const getPlanName = (planId) => {
        switch (planId) {
            case 2:
                return "Experto";
            case 10:
                return "Subfacturacion_Lite";
            case 6:
                return "Profesional";
            case 5:
                return "Básico";
            case 4:
                return "Lite";
            default:
                return "Desconocido"
        }
    }

    return (
        <>
            <TopNav titulo="Pagos" subtitulo='| Historial de compras' />
            <Container>
                {loading ?
                    <Box mt={6} display='flex' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Link to="/pagos">
                            <IconButton
                                aria-label='seleccionar'
                            >
                                <ChevronLeftIcon fontSize='inherit' />
                            </IconButton>
                        </Link>

                        <Box p={4} pt={0}>
                            {purchases?.length > 0 ?

                                <TableContainer component={Paper}>
                                    <Table
                                        size="small"
                                        aria-label="Historial de alerta"
                                        className={classes.table}
                                    >
                                        <TableHead>
                                            <TableRow className={classes.header}>
                                                {labelsTableHeader?.map(label => {
                                                    return (
                                                        <TableCell key={label} align="center" className={classes.headerCell}>
                                                            {label}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {purchases?.map(purchase => {
                                                return (
                                                    <TableRow key={purchase.id} hover>
                                                        <TableCell align="center">
                                                            {getPlanName(purchase.plan)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {purchase.perks.length}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {purchase.duracion}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {purchase.fecha_creacion}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {purchase.fecha_pago}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {purchase.estado}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Box className={classes.noResultsBox}>
                                    No hay resultados.
                                </Box>
                            }


                        </Box>
                    </>
                }
            </Container>
        </>
    )
}