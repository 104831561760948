export const benefits = [
  {
    plan: 'Gratuito',
    headers: [
        'Ideal para hogares',
        '1 localización',
        '0 cuentas invitado',
      ],
    modules: [
      'App web y mobile (iOS y Android)',
      '3 dispositivos (máximo)',
      '7 días de mediciones instantaneas',
      '2 años de acumulados diarios de energia',
      "<span style='text-decoration: line-through;'>Potencia adquirida y energía por hora</span>",
      'Notificaciones en app únicamente',
      'Acceso a secciones básicas',
    ],
  },
  {
    plan: 'Lite',
    headers: [
      'Ideal para hogares (uso avanzado) y comercios pequeños',
      '1 localización',
      '0 cuentas invitado',
    ],
    modules: [
      'App web y mobile (iOS y Android)',
      '5 dispositivos (máximo)',
      '30 días de mediciones instantaneas',
      '4 años de acumulados diarios de energia',
      "<span style='text-decoration: line-through;'>Potencia adquirida y energía por hora</span>",
      'Notificaciones en app únicamente',
      'Acceso a secciones básicas',
    ],
  },
  {
    plan: 'Basico',
    headers: [
      'Ideal para pequeñas empresas',
      '1 localización',
      '2 cuentas invitado',
    ],
    modules: [
      'App web y mobile (iOS y Android)',
      '10 dispositivos (ampliable)',
      '30 días de mediciones instantaneas',
      '4 años de acumulados diarios de energia',
      'Potencia adquirida y energía por hora',
      'Notificaciones en app, mail y telegram',
      'Acceso a secciones avanzadas',
    ],
  },
  {
    plan: 'Profesional',
    headers: [
      'Ideal para empresas medianas multi-sitio',
      '5 localizaciones',
      '5 cuentas invitado',
    ],
    modules: [
      'App web y mobile (iOS y Android)',
      '10 dispositivos (ampliable)',
      '90 días de mediciones instantaneas',
      '4 años de acumulados diarios de energia',
      'Potencia adquirida y energía por hora',
      'Notificaciones en app, mail y telegram',
      'Acceso a secciones avanzadas',
    ],
  },
  {
    plan: 'Experto',
    headers: [
      'Ideal grandes organizaciones y profesionales en gestión de la energía',
      'Localizaciones ilimitadas',
      '10 cuentas invitado',
    ],
    modules: [
      'App web y mobile (iOS y Android)',
      '10 dispositivos (ampliable)',
      '90 días de mediciones instantaneas',
      '4 años de acumulados diarios de energia',
      'Potencia adquirida y energía por hora',
      'Notificaciones en app, mail y telegram',
      'Acceso a secciones avanzadas',
    ],
  },
  {
    plan: 'Subfacturacion_Lite',
    headers: [
      'Ideal grandes organizaciones y profesionales en gestión de la energía',
      'Localizaciones ilimitadas',
      '10 cuentas invitado',
    ],
    modules: [
      'App web y mobile (iOS y Android)',
      '10 dispositivos (ampliable)',
      '90 días de mediciones instantaneas',
      '4 años de acumulados diarios de energia',
      'Potencia adquirida y energía por hora',
      'Notificaciones en app, mail y telegram',
      'Acceso a secciones avanzadas',
    ],
  },
];
