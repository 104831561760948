import {
    Box, IconButton, Paper,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Tooltip, makeStyles
} from '@material-ui/core';
//Icons
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@mui/icons-material/History';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GrainIcon from '@mui/icons-material/Grain';
import HubIcon from '@mui/icons-material/Hub';
const useStyles = makeStyles(theme => ({
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    header: {
        background: theme.palette.primary.main,
    },
    headerCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    titleHeader: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
    },
    noResultsBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        textAlign: 'center',
    },
    estadoSyle: {
        fontSize: theme.spacing(1.8),
        cursor: 'pointer'
    },
    spinning: {
        animation: `$spin 2s infinite`,
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '50%': { transform: 'rotate(180deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
}));

const AlertPlcTable = ({ plcComponent, diagramComponent, labelsTableHeader, dataList, handleDirectionDiagram,
    setSelectedAlert, setIsDeleteDialogOpen, handleAlertRecord, title, dataDiagramFreeList }) => {
    const classes = useStyles()

    const showHistoryIcon = () => {
        if (plcComponent) {
            return false
        }
        if (diagramComponent) {
            return false
        }
        return true
    }

    const returnValueDateTable = (element) => {
        if (plcComponent) {
            return element?.last_send?.modified_format ? element?.last_send?.modified_format : ''
        }
        if (diagramComponent) {
            return element?.modified_format
        }
        return element.fecha_alta_local || element.created_format
    }

    const checkDiagramMatch = (element) => {
        let resAux = {
            color: 'red',
            label: element?.diagram?.name?.lengh > 0 ? element?.diagram?.name : 'No posee diagrama'
        }
        let elementAux = dataDiagramFreeList.find(e => e?.name === element?.diagram?.name)
        if (elementAux?.id) {
            resAux = {
                color: 'green',
                label: elementAux?.name
            }
        }
        return resAux
    }

    return (
        <Box m={1} width={'100%'}>
            {dataList ?
                <TableContainer component={Paper}>
                    <Table
                        size='small'
                        aria-label='Tabla de alertas'
                        className={classes.table}
                    >

                        <TableHead>
                            <Box width={'100%'} className={classes.titleHeader}>
                                {title}
                            </Box>
                            <TableRow className={classes.header}>
                                {labelsTableHeader?.map((label, indx) => {
                                    return (
                                        <>
                                            {indx === 0
                                                ?
                                                <TableCell key={label} className={classes.headerCell}>
                                                    <Box ml={1}>
                                                        {label}
                                                    </Box>
                                                </TableCell>
                                                :
                                                <TableCell key={label} align='center' className={classes.headerCell}>
                                                    {label}
                                                </TableCell>
                                            }
                                        </>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList?.map(element => {
                                return (
                                    <TableRow key={element.id} hover>
                                        {/* {
                                        !plcComponent &&
                                        <TableCell align='center'>
                                            <>
                                                {!diagramComponent &&
                                                    <Switch color='primary' onChange={(event) => {
                                                        handleSetAlertState(element, event?.target?.checked);
                                                    }}
                                                        checked={element.activo || element.activate}></Switch>
                                                }
                                            </>
                                        </TableCell>
                                    } */}
                                        {/* {
                                        plcComponent &&
                                        <TableCell align='center'>
                                            <Box display={'flex'} justifyContent={'center'}>
                                                {returnButtonState(element)}
                                            </Box>

                                        </TableCell>
                                    } */}
                                        <TableCell>
                                            {
                                                diagramComponent ?
                                                    <Box ml={1} >
                                                        {element.nombre || element.name}
                                                    </Box>
                                                    :
                                                    <Tooltip
                                                        title={element?.llave}
                                                        arrow
                                                        placement='bottom'
                                                    >
                                                        <Box ml={1} style={{ cursor: 'pointer' }}>
                                                            {element.nombre || element.name}
                                                        </Box>
                                                    </Tooltip>
                                            }
                                        </TableCell>
                                        {
                                            plcComponent &&
                                            <TableCell>
                                                <Box ml={1}>
                                                    <Tooltip
                                                        title={checkDiagramMatch(element)?.label}
                                                        arrow
                                                        placement='bottom'
                                                    >
                                                        {/* HubIcon */}
                                                        <GrainIcon style={{ color: checkDiagramMatch(element)?.color, marginLeft: '30px', fontSize: '25px', cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        }

                                        <TableCell align='center'>
                                            {returnValueDateTable(element)}
                                        </TableCell>
                                        {
                                            showHistoryIcon() &&
                                            <TableCell align='center'>
                                                <Tooltip
                                                    title={'Historial'}
                                                    arrow
                                                    placement='bottom'
                                                >
                                                    <IconButton onClick={() => handleAlertRecord(element)}>
                                                        <HistoryIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        <TableCell align='center'>
                                            <Tooltip
                                                title={plcComponent ? 'Ver diagrama' : 'Editar'}
                                                arrow
                                                placement='bottom'
                                            >
                                                <IconButton onClick={() => handleDirectionDiagram(element.id)}>
                                                    {plcComponent ?
                                                        <VisibilityIcon />
                                                        :
                                                        <EditIcon />
                                                    }

                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        {
                                            !plcComponent &&
                                            <TableCell align='center'>
                                                <Tooltip
                                                    title={plcComponent ? 'Eliminar diagrama' : 'Eliminar alerta'}
                                                    arrow
                                                    placement='bottom'
                                                >
                                                    <IconButton onClick={() => {
                                                        setIsDeleteDialogOpen(true);
                                                        setSelectedAlert(element)
                                                    }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <Box className={classes.noResultsBox}>
                    No hay resultados.
                </Box>
            }
        </Box >
    )
}
export default AlertPlcTable;